/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable new-cap */
import React, { useState, useEffect, useCallback } from 'react';

import dayjs from 'dayjs';
import styled from 'styled-components';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Theme from '../../../../theme/Theme';
import AdPerformance from './AdPerformanceView/AdPerformance';
import AmcInsightContainer from './AmcInsights/AmcInsightContainer';
import CreativeProjectContainer from './CreativeProjects/CreativeProjectContainer';
import UploadCreativeAsins from './CreativeProjects/UploadCreativeAsins';
import SalesPerformanceContainer from './SalesPerformance/SalesPerformanceContainer';
import DspPerformanceContainer from './DspAdPerformance/DspPerformanceContainer';
import { RefreshButton } from '../Playbook/PlaybookStyle';
import {
  getChildBps,
  getDataAvailabeleChildBrand,
  getMarketPlaceList,
  getSalesAndAdMinDate,
  getGoalsMarketPlaceList,
} from '../../../../api';
import {
  AllocateBar,
  DropdownIndicator,
  DropdownOptions,
  DropdownSingleValue,
  SingleSelectDropdown,
  Tabs,
} from '../../../../common';
import {
  ADPERFORMANCE,
  AMCINSIGHTS,
  childBrandDataAvailableMessage,
  CREATIVEPROJECTS,
  CUSTOM,
  DSPPERFORMANCE,
  HYBRID,
  PARENT,
  PATH_CUSTOMER_DETAILS,
  SALEPERFORMANCE,
  SELLER,
  SELLERSMALL,
  VENDOR,
  VENDORSMALL,
} from '../../../../constants';

const getSymbolFromCurrency = require('currency-symbol-map');

export default function CompanyPerformance({
  marketplaceChoices,
  id,
  memberData,
  // showAdperformanceSpecificBP,
  // showSalesReportSpecificBP,
  customer,
  childBPList,
  isBBEInternalUser,
  setShowCreativeUploadScreen,
  showCreativeUploadScreen,
}) {
  const history = useHistory();
  const currentDate = new Date();
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const customerState = useSelector((state) => state?.customerState?.data);
  const isSuperAdmin = userInfo?.role === 'Super Admin';
  const isThirdPartyBrand = customerState?.is_third_party_potential_brand;
  const hideSalesAndSponsoredTab = !isSuperAdmin && !isThirdPartyBrand;
  // https://bbe.atlassian.net/browse/PDV-9256 - Hide Creative Projects Tab on Single BP View
  const hideCreativeProjectTab = !isSuperAdmin;
  const dspPpcFlag = {
    isHavingDsp: customerState?.having_contract_type?.is_having_dsp,
    isHavingPpc: customerState?.having_contract_type?.is_having_ppc,
  };

  const customerAccountType = customerState?.customer_account_type;
  const isSeller =
    customerAccountType === SELLER || customerAccountType === HYBRID;
  const isVendor =
    customerAccountType === VENDOR || customerAccountType === HYBRID;

  const [isDspDashboardDisabled, setIsDspDashboardDisabled] = useState(
    !dspPpcFlag?.isHavingDsp,
  );
  const [selectedMarketplace, setSelectedMarketplace] = useState({});
  const [marketplaceOptions, setMarketplaceOptions] = useState([]);
  const [currency, setCurrency] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [sellerSalesMinDate, setSellerSalesMinDate] = useState(null);
  const [sellerAdMinDate, setSellerAdMinDate] = useState(null);
  const [vendorSalesMinDate, setVendorSalesMinDate] = useState(null);
  const [vendorAdMinDate, setVendorAdMinDate] = useState(null);
  const [minDateLoader, setMinDateLoader] = useState(false);
  const [selectedChildBrand, setSelectedChildBrand] = useState(null);
  const [childBrandOptions, setChildBrandOptions] = useState(childBPList);
  const [actualChildList, setActualChildList] = useState(childBPList);
  const [salesDataAvailableChildList, setSalesDataAvailableChildList] =
    useState([]);
  const [adsDataAvailableChildList, setAdsDataAvailableChildList] = useState(
    [],
  );
  const [dspDataAvailableChildList, setDspDataAvailableChildList] = useState(
    [],
  );
  const [selectedAdType, setSelectedAdType] = useState({
    value: 'all',
    label: 'All Ad Types',
  });
  const [selectedTimeFrame, setSelectedTimeFrame] = useState({
    value: 'week',
    label: 'Current 7 days',
    sub: 'vs Previous 7 days',
  });

  const [assignedBGSManager, setAssignedBGSManager] = useState(false);

  const params = queryString.parse(history.location.search);

  // account type options based on customer account type
  const accountTypeOptions = [
    {
      label: 'Seller Reporting',
      value: SELLERSMALL,
      isDisabled: customerAccountType === VENDOR,
    },
    {
      label: 'Vendor Reporting',
      value: VENDORSMALL,
      isDisabled: customerAccountType === SELLER,
    },
  ];

  const [selectedAccountType, setSelectedAccountType] = useState(
    isSeller ? accountTypeOptions?.[0] : accountTypeOptions?.[1],
  );
  const [isCallSPAsinApi, setIsCallSPAsinApi] = useState(false);

  const setTab = () => {
    if (history.location.state === AMCINSIGHTS) {
      setTimeout(() => {
        document.getElementById('scroll').scrollRight += 1000;
      }, 1000);
    }
    if (history.location.state === 'dspMetrics') {
      setTimeout(() => {
        window.scrollTo(0, 1500);
      }, 1000);
    }

    // https://bbe.atlassian.net/browse/PDV-9525 - FE 2 - BP / Internal / Partner List Views - Stop pulling Seller and Vendor data via Amazon APIs
    // Logic - If user is not Super Admin AND BP is BBE BP i.e. not Spreetail bp then redirect user to dsp ad performance otherwise keep as it is.
    if (hideSalesAndSponsoredTab) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', customer?.id)}?tab=${
          params?.tab
        }&nav=${DSPPERFORMANCE}&access=${isBBEInternalUser}`,
        DSPPERFORMANCE,
      );
      return DSPPERFORMANCE;
    }
    if (
      [
        SALEPERFORMANCE,
        ADPERFORMANCE,
        DSPPERFORMANCE,
        AMCINSIGHTS,
        CREATIVEPROJECTS,
      ].includes(history?.location?.state)
    ) {
      return history?.location?.state;
    }
    /* ********** PDV-7917 - Expose AMC and other Sections to BPs ********** */
    /* ********** PLEASE DO NOT REMOVE BELOW CODE. ********** */
    // if (
    //   (userInfo?.role?.includes('Customer') &&
    //     showAdperformanceSpecificBP &&
    //     !showSalesReportSpecificBP) ||
    //   history.location.state === 'adManager' ||
    //   history.location.state === 'adPerformance' ||
    //   history.location.state === 'dspMetrics'
    // ) {
    //   return 'adPerformance';
    // }

    return SALEPERFORMANCE;
  };

  const [sellerViewComponent, setSellerViewComponent] = useState(setTab);

  currentDate.setDate(currentDate.getDate() - 2);

  const [selectedState, setSelectedState] = useState([
    {
      startDate: currentDate,
      endDate: currentDate,
      key: 'selection',
    },
  ]);
  const [customDateError, setCustomDateError] = useState({});

  const [vendorGoalsMarketplaceOptions, setVendorGoalsMarketplaceOptions] =
    useState([]);
  const [sellerGoalsMarketplaceOptions, setSellerGoalsMarketplaceOptions] =
    useState([]);

  const [sellerGoalsMarketplaceIds, setSellerGoalsMarketplaceIds] = useState(
    [],
  );
  const [vendorGoalsMarketplaceIds, setVendorGoalsMarketplaceIds] = useState(
    [],
  );

  const [showRefreshBtn, setShowRefreshBtn] = useState(false);
  const [resetCreativeProjectFilters, setResetCreativeProjectFilters] =
    useState(false);
  const [isDisabledRefreshBtn, setIsDisabledRefreshBtn] = useState(true);

  useEffect(() => {
    // If AMC Insight tab is selected then move the horizontal scrollbar at the right end.

    const scrollBarElement = document?.querySelector('.scrollbar-outer');
    if (
      (params?.nav === AMCINSIGHTS || params?.nav === CREATIVEPROJECTS) &&
      scrollBarElement
    ) {
      setTimeout(() => {
        const scrollBarElementWidth = scrollBarElement?.clientWidth;
        scrollBarElement.scrollLeft = scrollBarElementWidth;
      }, 100);
    }
  }, [params.nav]);

  // get goals marketplace id by selected marketplace
  const getGoalsMarketplaceIdBySelectedMarketplace = useCallback(
    (initialMarketplace, goalsMarketplaceOptions) => {
      let marketplaceIds = [];
      if (initialMarketplace?.value === 'all') {
        marketplaceIds = [];
      } else {
        marketplaceIds = goalsMarketplaceOptions
          ?.filter((marketplace) => {
            return initialMarketplace?.label?.includes(marketplace?.label)
              ? marketplace?.id
              : '';
          })
          ?.map((item) => item?.id);
      }
      return marketplaceIds;
    },
    [],
  );

  // goals marketplace ids by account type
  const createMarektplaceIdsListOfGoals = useCallback(
    (initialMarketplace, marketplaceList, accountType = null) => {
      const marketplaceId = getGoalsMarketplaceIdBySelectedMarketplace(
        initialMarketplace,
        marketplaceList,
      );
      if (accountType === null) {
        if (isSeller) {
          setSellerGoalsMarketplaceIds(marketplaceId);
        }
        if (isVendor) {
          setVendorGoalsMarketplaceIds(marketplaceId);
        }
      } else if (accountType === SELLER) {
        setSellerGoalsMarketplaceIds(marketplaceId);
      } else {
        setVendorGoalsMarketplaceIds(marketplaceId);
      }
    },
    [getGoalsMarketplaceIdBySelectedMarketplace],
  );

  // get goals marketplaces list
  const getGoalMarketPlacesData = useCallback(
    (initialMarketplace, accountType) => {
      let finalMarketplaces;

      getGoalsMarketPlaceList(id, accountType).then((res) => {
        if (res?.status === 200 && res?.data?.length) {
          finalMarketplaces = res?.data;

          const marketPlaceList = [];
          if (finalMarketplaces?.length) {
            for (const option of finalMarketplaces) {
              marketPlaceList.push({
                value: option?.name,
                label: option?.country_currency.country,
                id: option?.id,
                collapse: true,
              });
            }
            if (accountType === SELLER) {
              setSellerGoalsMarketplaceOptions(marketPlaceList);
            } else {
              setVendorGoalsMarketplaceOptions(marketPlaceList);
            }
            createMarektplaceIdsListOfGoals(
              initialMarketplace,
              marketPlaceList,
              accountType,
            );
          }
        }
      });
    },
    [createMarektplaceIdsListOfGoals, id],
  );

  // use effect for binding the marketplaces and currency symbol
  useEffect(() => {
    let marketPlaceList = [];
    if (marketplaceChoices?.length) {
      for (const option of marketplaceChoices) {
        marketPlaceList.push({
          value: option?.name,
          label: `${option?.country_currency?.country} ${
            option?.name !== 'all' && !option?.is_active ? '( Inactive )' : ''
          }`,
          currency: option?.country_currency?.currency,
          isPrimary: option?.is_primary,
          id: option?.id,
          isActive: option?.name === 'all' ? true : option?.is_active,
        });
      }
      if (marketPlaceList?.length === 2) {
        marketPlaceList = marketPlaceList.filter(
          (item) => item?.value !== 'all',
        );
      }
      let initialMarketplace = {};
      setMarketplaceOptions(marketPlaceList);

      if (customerState?.account_type === PARENT) {
        [initialMarketplace] = marketPlaceList;
      } else {
        initialMarketplace =
          marketPlaceList.length > 1 ? marketPlaceList[1] : marketPlaceList[0];
      }

      setSelectedMarketplace(initialMarketplace);
      setCurrency(initialMarketplace?.currency);
      setCurrencySymbol(getSymbolFromCurrency(initialMarketplace?.currency));

      if (isSeller) {
        getGoalMarketPlacesData(initialMarketplace, SELLER);
      }
      if (isVendor) {
        getGoalMarketPlacesData(initialMarketplace, VENDOR);
      }
    }
  }, [marketplaceChoices]);

  useEffect(() => {
    if (childBPList.length) {
      const localChildBrandList = JSON.parse(JSON.stringify(childBPList));
      localChildBrandList.splice(0, 0, {
        value: 'all',
        label: 'All Brands',
        status: 'active',
        bgs: null,
      });
      setChildBrandOptions(localChildBrandList);
      setActualChildList(childBPList);
    }
  }, [childBPList]);

  // set the "all brand" option
  useEffect(() => {
    if (customerState?.account_type === PARENT) {
      setSelectedChildBrand({
        value: 'all',
        label: 'All Brands',
        isDisabled: false,
        bgs: null,
      });
    }
  }, []);

  // bind the marketplace options
  const bindMarketplaceOptions = (responseData) => {
    const localMarketplaceList = [];

    marketplaceChoices.forEach((element) => {
      const matchElement = responseData.find((op) => op.name === element.name);

      if (matchElement !== undefined) {
        localMarketplaceList.push({
          ...element,
          isDisabled: false,
          value: element?.name,
          label: element?.country_currency.country,
          currency: element?.country_currency.currency,
        });
      } else {
        localMarketplaceList.push({
          ...element,
          isDisabled: element?.name !== 'all',
          value: element?.name,
          label: element?.country_currency.country,
          currency: element?.country_currency.currency,
        });
      }

      return true;
    });

    // localMarketplaceList = localMarketplaceList.sort((marketplace) =>
    //   marketplace?.name === 'Amazon.com' && marketplace?.is_primary
    //     ? -1
    //     : marketplace?.name === 'Amazon.com'
    //     ? -1
    //     : 1,
    // );

    setMarketplaceOptions(localMarketplaceList);
  };

  // API- get the marketplace list based on selected child brand
  const getMarketPlaceOnchild = useCallback((custId) => {
    getMarketPlaceList(custId).then((res) => {
      if (res?.status === 200) {
        bindMarketplaceOptions(res?.data);
      }
    });
  }, []);

  // get the marketplace list based on selected child brand
  useEffect(() => {
    if (selectedChildBrand !== null) {
      if (selectedChildBrand.value !== 'all') {
        getMarketPlaceOnchild(selectedChildBrand.value);
      } else bindMarketplaceOptions(marketplaceChoices);
    }
  }, [selectedChildBrand, getMarketPlaceOnchild]);

  // bind the child brand list
  const bindChidBrands = (childBrandResponse) => {
    const localChildBrand = [
      {
        value: 'all',
        label: 'All Brands',
        isDisabled: false,
        bgs: null,
      },
    ];

    if (childBPList?.length > 0) {
      childBPList.forEach((element) => {
        const matchElement = childBrandResponse.find(
          (op) => op.value === element.value,
        );
        if (matchElement !== undefined) {
          localChildBrand.push({
            ...element,
            isDisabled: false,
          });
        } else {
          localChildBrand.push({
            ...element,
            isDisabled: true,
          });
        }
        return true;
      });
    }

    setChildBrandOptions(localChildBrand);
  };

  // API - get the child brand list based on selected marketplace
  const getChildBpList = (customerId, marketplaceId) => {
    getChildBps(customerId, marketplaceId).then((res) => {
      if (res?.status === 200) {
        if (res?.data.length) {
          bindChidBrands(res?.data);
          setActualChildList(res?.data);
        }
      }
    });
  };

  // get the child brand list based on selected marketplace
  useEffect(() => {
    if (
      customerState?.account_type === PARENT &&
      selectedMarketplace &&
      selectedMarketplace?.value
    ) {
      if (selectedMarketplace?.value === 'all') {
        bindChidBrands(childBPList);
      } else {
        getChildBpList(id, selectedMarketplace?.value);
      }
    }
  }, [selectedMarketplace]);

  const renderDataAvailableChildList = (messageFor) => {
    let brandList = [];
    if (messageFor === 'sales') {
      brandList = salesDataAvailableChildList;
    } else if (messageFor === 'sponsored') {
      brandList = adsDataAvailableChildList;
    } else if (messageFor === 'dsp') {
      brandList = dspDataAvailableChildList;
    }
    const lengthOfbrand = brandList.length;
    let isShowMessage = false;

    if (
      selectedChildBrand &&
      selectedChildBrand?.value === 'all' &&
      actualChildList?.length !== lengthOfbrand
    ) {
      isShowMessage = true;
    }

    return (
      <>
        {isShowMessage && lengthOfbrand ? (
          <AllocateBar className="mb-4" key={messageFor}>
            <div className="row">
              <div className="col-12">
                {' '}
                <div className="remaing-label">
                  <FontAwesomeIcon
                    icon=" fal fa-info-circle"
                    color={Theme.black}
                    fontSize="16px"
                  />

                  <span> Only </span>
                  {brandList?.map((messageWord, index) => {
                    return (
                      <span style={{ color: Theme.orange }}>
                        {lengthOfbrand !== 1 && index === lengthOfbrand - 1 ? (
                          <span style={{ color: Theme.black }}> and </span>
                        ) : null}
                        {messageWord?.bp_name}
                        {lengthOfbrand !== 1 &&
                        index !== lengthOfbrand - 1 &&
                        index !== lengthOfbrand - 2 ? (
                          <span style={{ color: Theme.black }}>, </span>
                        ) : null}
                      </span>
                    );
                  })}

                  {lengthOfbrand === 1 ? (
                    <span> has </span>
                  ) : (
                    <span> have </span>
                  )}
                  <span>{childBrandDataAvailableMessage[messageFor]}</span>
                </div>{' '}
              </div>
            </div>
          </AllocateBar>
        ) : null}
      </>
    );
  };

  // bind the error message if data not available for selecetd custom date
  const bindErrorMessage = (
    type,
    minDate,
    accountType = selectedAccountType?.value,
    customState = selectedState,
  ) => {
    const dates = [];

    dates.push(new Date(dayjs(customState[0].startDate).format('MM/DD/YYYY')));
    dates.push(new Date(dayjs(minDate).format('MM/DD/YYYY')));

    // if selected  date is lower that data_availeble_date then we need to show the error message
    if (dates[0] < dates[1]) {
      setCustomDateError({
        accountType,
        selectedTab: type,
        fromDate: dates[0],
        toDate: dates[1],
      });
    } else {
      setCustomDateError({});
    }
  };

  // clear use state if api failed
  const clearingUseStateHooksForMinDateAPI = useCallback(() => {
    setSellerSalesMinDate(null);
    setSellerAdMinDate(null);
    setVendorSalesMinDate(null);
    setVendorAdMinDate(null);
  }, []);

  // API to get the min date of data availability for sales, sposnored,
  const getMinDate = useCallback(
    (marketplace, adType, accountType, customState, selectedTab) => {
      setMinDateLoader(true);
      getSalesAndAdMinDate(id, marketplace, adType, accountType).then((res) => {
        if (res?.status === 200) {
          if (res?.data[0]) {
            if (accountType === SELLERSMALL) {
              setSellerSalesMinDate(res?.data[0].sales_performance_date);
              setSellerAdMinDate(new Date('08/01/2022')); // PDV-6723 set mindate Aug 1, 2022.
            } else {
              setVendorSalesMinDate(res?.data[0].sales_performance_date);
              setVendorAdMinDate(new Date('08/01/2022')); // PDV-6723 set mindate Aug 1, 2022.
            }

            if (selectedTab === SALEPERFORMANCE) {
              bindErrorMessage(
                SALEPERFORMANCE,
                res?.data[0].sales_performance_date,
                accountType,
                customState,
              );
            } else {
              bindErrorMessage(
                ADPERFORMANCE,
                res?.data[0].sponsered_performance_date,
                accountType,
                customState,
              );
            }
          } else {
            clearingUseStateHooksForMinDateAPI();
          }
          setMinDateLoader(false);
        } else {
          clearingUseStateHooksForMinDateAPI();
          setMinDateLoader(false);
        }
      });
    },
    [clearingUseStateHooksForMinDateAPI, id],
  );

  // get min date for sales and sposored ad performance
  useEffect(() => {
    if (selectedMarketplace?.value)
      getMinDate(
        selectedMarketplace?.value,
        selectedAdType?.value,
        selectedAccountType?.value,
        selectedState,
        sellerViewComponent,
      );
  }, [getMinDate, selectedAdType, selectedMarketplace, selectedAccountType]);

  // API to get the data available child brand list
  const getDataAvailabeleChildBrandList = useCallback(
    (
      customerId,
      dashboard,
      accountType = null,
      marketplace,
      sponsoredType,
      timeframe,
      startDate,
      endDate,
    ) => {
      if (customerState?.account_type === PARENT) {
        getDataAvailabeleChildBrand(
          customerId,
          dashboard,
          accountType,
          marketplace,
          sponsoredType,
          timeframe,
          startDate,
          endDate,
        ).then((res) => {
          if (res?.status === 200) {
            if (res?.data) {
              if (
                ['sales', 'orderedRevenue', 'shippedCOGs'].includes(dashboard)
              ) {
                setSalesDataAvailableChildList(res?.data);
              } else if (dashboard === 'sponsored') {
                setAdsDataAvailableChildList(res?.data);
              } else if (dashboard === 'dsp') {
                setDspDataAvailableChildList(res?.data);
              }
            }
          }
        });
      }
    },
    [id],
  );

  useEffect(() => {
    if (sellerViewComponent === CREATIVEPROJECTS) setShowRefreshBtn(true);
    else setShowRefreshBtn(false);
  }, [sellerViewComponent]);

  // handle performance tab click events
  const handleTabClick = (type) => {
    // handeling events when user click on sales performance tab
    if (type === SALEPERFORMANCE) {
      setSellerViewComponent(SALEPERFORMANCE);
      if (selectedTimeFrame.value === CUSTOM) {
        if (selectedAccountType?.value === SELLERSMALL) {
          bindErrorMessage(SALEPERFORMANCE, sellerSalesMinDate);
        } else {
          bindErrorMessage(SALEPERFORMANCE, vendorSalesMinDate);
        }
      }
    }

    // handeling events when user click on ad performance tab
    if (type === ADPERFORMANCE) {
      setSellerViewComponent(ADPERFORMANCE);
      if (selectedTimeFrame.value === CUSTOM) {
        if (selectedAccountType?.value === SELLERSMALL) {
          bindErrorMessage(ADPERFORMANCE, sellerAdMinDate);
        } else {
          bindErrorMessage(ADPERFORMANCE, vendorAdMinDate);
        }
      }
      if (selectedTimeFrame.value === 'year') {
        setSelectedTimeFrame({
          value: 'week',
          label: 'Current 7 days',
          sub: 'vs Previous 7 days',
        });
      }
    }

    // handeling events when user click on dsp performance tab
    if (type === DSPPERFORMANCE) {
      setSellerViewComponent(DSPPERFORMANCE);
    }

    // handeling events when user click on amc insights tab
    if (type === AMCINSIGHTS) {
      setSellerViewComponent(AMCINSIGHTS);
    }

    // handeling events when user click on creative project tab
    if (type === CREATIVEPROJECTS) {
      setSellerViewComponent(CREATIVEPROJECTS);
    }
  };

  const resetCreativeFilters = () => {
    setResetCreativeProjectFilters(true);
  };

  useEffect(() => {
    if (memberData) {
      for (const user of memberData) {
        if (user.user) {
          if (
            user?.role_group?.name === 'BGS Manager' &&
            user?.user?.id === userInfo?.id
          ) {
            setAssignedBGSManager(true);
          }
        }
      }
    }
  }, [memberData]);

  // get dropdown components
  const getDropdDownComponents = () => {
    return {
      Option: DropdownOptions,
      SingleValue: DropdownSingleValue,
      DropdownIndicator,
    };
  };

  // handle account type change events
  const handleAccountTypeChangeEvents = (event) => {
    if (event?.value !== selectedAccountType?.value) {
      setSelectedAccountType(event);
      setIsCallSPAsinApi(true);
      setShowCreativeUploadScreen(false);

      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', customer.id)}?tab=${
          params?.tab
        }&sub=${event?.value}&nav=${
          params?.nav || SALEPERFORMANCE
        }&access=${isBBEInternalUser}`,
      );
    }
  };

  // account type filter
  const displayAccountTypeFilter = (isApiCall) => {
    return (
      <div id="account-type-filter" style={{ position: 'relative' }}>
        <SingleSelectDropdown
          filterId="BT-AccountTypeFilter"
          isApiCall={isApiCall}
          dropdownOptions={accountTypeOptions}
          selectedValue={selectedAccountType}
          onChangeHandler={handleAccountTypeChangeEvents}
          dropdownComponents={getDropdDownComponents}
          isSearchable={false}
        />
      </div>
    );
  };

  // render Ad performance component
  const renderAdPerformanceComponent = () => {
    return (
      <AdPerformance
        id={id}
        dspPpcFlag={dspPpcFlag}
        isCallSPAsinApi={isCallSPAsinApi}
        accountType={selectedAccountType?.value}
        selectedAdDF={selectedTimeFrame}
        setSelectedAdDF={setSelectedTimeFrame}
        adState={selectedState}
        setAdState={setSelectedState}
        currentDate={currentDate}
        selectedMarketplace={selectedMarketplace}
        setSelectedMarketplace={setSelectedMarketplace}
        marketplaceOptions={marketplaceOptions}
        currency={currency}
        setCurrency={setCurrency}
        currencySymbol={currencySymbol}
        setCurrencySymbol={setCurrencySymbol}
        selectedAdType={selectedAdType}
        setSelectedAdType={setSelectedAdType}
        adMinDate={sellerAdMinDate}
        customDateError={customDateError}
        subViewComponent={selectedAccountType?.value}
        bindErrorMessage={bindErrorMessage}
        minDateLoader={minDateLoader}
        goalsMarketplaceIds={
          selectedAccountType?.value === SELLERSMALL
            ? sellerGoalsMarketplaceIds
            : vendorGoalsMarketplaceIds
        }
        goalsMarketplaceOptions={
          selectedAccountType?.value === SELLERSMALL
            ? sellerGoalsMarketplaceOptions
            : vendorGoalsMarketplaceOptions
        }
        childBrandOptions={childBrandOptions}
        selectedChildBrand={selectedChildBrand}
        setIsCallSPAsinApi={setIsCallSPAsinApi}
        setSelectedChildBrand={setSelectedChildBrand}
        displayAccountTypeFilter={displayAccountTypeFilter}
        renderDataAvailableChildList={renderDataAvailableChildList}
        getDataAvailabeleChildBrandList={getDataAvailabeleChildBrandList}
        createMarektplaceIdsListOfGoals={createMarektplaceIdsListOfGoals}
      />
    );
  };

  // display dsp performance section
  const displayDspPerformanceSection = () => {
    return (
      <div className={isDspDashboardDisabled ? 'disabled' : ''}>
        <DspPerformanceContainer
          customerId={id}
          dspPpcFlag={dspPpcFlag}
          selectedChildBrand={selectedChildBrand}
          memberData={memberData}
          childBrandOptions={childBrandOptions}
          setSelectedChildBrand={setSelectedChildBrand}
          setIsDspDashboardDisabled={setIsDspDashboardDisabled}
          renderDataAvailableChildList={renderDataAvailableChildList}
          getDataAvailabeleChildBrandList={getDataAvailabeleChildBrandList}
          getGoalsMarketplaceIdBySelectedMarketplace={
            getGoalsMarketplaceIdBySelectedMarketplace
          }
        />
      </div>
    );
  };

  // render AMC Insight component
  const renderAMCInsightsComponent = () => {
    return (
      <AmcInsightContainer
        customerID={id}
        memberData={memberData}
        isBBEInternalUser={isBBEInternalUser}
      />
    );
  };

  // render creative project container
  const renderCreativeProjectContainer = () => {
    return (
      <CreativeProjectContainer
        id={id}
        resetCreativeProjectFilters={resetCreativeProjectFilters}
        setResetCreativeProjectFilters={setResetCreativeProjectFilters}
        setShowCreativeUploadScreen={setShowCreativeUploadScreen}
        accountType={selectedAccountType?.value}
        selectedAccountType={selectedAccountType}
        assignedBGSManager={assignedBGSManager}
        loggedInRole={userInfo?.role}
        setIsDisabledRefreshBtn={setIsDisabledRefreshBtn}
        displayAccountTypeFilter={displayAccountTypeFilter}
      />
    );
  };

  // display sales performance container
  const displaySalesPerformanceContainer = () => {
    return (
      <SalesPerformanceContainer
        customerId={id}
        currency={currency}
        currencySymbol={currencySymbol}
        subViewComponent={selectedAccountType?.value}
        sellerSalesMinDate={sellerSalesMinDate}
        vendorSalesMinDate={vendorSalesMinDate}
        dspPpcFlag={dspPpcFlag}
        minDateLoader={minDateLoader}
        currentDate={currentDate}
        customDateError={customDateError}
        selectedTimeFrame={selectedTimeFrame}
        selectedChildBrand={selectedChildBrand}
        selectedAccountType={selectedAccountType}
        selectedMarketplace={selectedMarketplace}
        selectedState={selectedState}
        childBrandOptions={childBrandOptions}
        marketplaceOptions={marketplaceOptions}
        goalsMarketplaceIds={
          selectedAccountType?.value === SELLERSMALL
            ? sellerGoalsMarketplaceIds
            : vendorGoalsMarketplaceIds
        }
        goalsMarketplaceOptions={
          selectedAccountType?.value === SELLERSMALL
            ? sellerGoalsMarketplaceOptions
            : vendorGoalsMarketplaceOptions
        }
        setCurrency={setCurrency}
        bindErrorMessage={bindErrorMessage}
        setSelectedState={setSelectedState}
        setCurrencySymbol={setCurrencySymbol}
        setSelectedTimeFrame={setSelectedTimeFrame}
        setSelectedChildBrand={setSelectedChildBrand}
        setSelectedMarketplace={setSelectedMarketplace}
        displayAccountTypeFilter={displayAccountTypeFilter}
        renderDataAvailableChildList={renderDataAvailableChildList}
        getDataAvailabeleChildBrandList={getDataAvailabeleChildBrandList}
        createMarektplaceIdsListOfGoals={createMarektplaceIdsListOfGoals}
      />
    );
  };

  // display performance tab components
  const displayPerformanceTabComponents = () => {
    return sellerViewComponent === SALEPERFORMANCE
      ? displaySalesPerformanceContainer()
      : sellerViewComponent === ADPERFORMANCE
      ? renderAdPerformanceComponent()
      : sellerViewComponent === DSPPERFORMANCE
      ? displayDspPerformanceSection()
      : sellerViewComponent === AMCINSIGHTS
      ? renderAMCInsightsComponent()
      : sellerViewComponent === CREATIVEPROJECTS
      ? renderCreativeProjectContainer()
      : null;
  };

  // handle performance tab click events
  const handlePerformanceTabClickEvents = (key) => {
    handleTabClick(key);
    if (key === DSPPERFORMANCE) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', customer?.id)}?tab=${
          params?.tab
        }&nav=${key}&access=${isBBEInternalUser}`,
        key,
      );
    } else if (key === AMCINSIGHTS) {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', customer?.id)}?tab=${
          params?.tab
        }&nav=amcInsights&subNav=${
          params?.subNav || 'standard'
        }&access=${isBBEInternalUser}`,
        AMCINSIGHTS,
      );
    } else {
      history.push(
        `${PATH_CUSTOMER_DETAILS.replace(':id', customer?.id)}?tab=${
          params?.tab
        }&sub=${
          params?.sub || SELLERSMALL
        }&nav=${key}&access=${isBBEInternalUser}`,
        key,
      );
    }
  };

  // display Performance Tab List html
  const displayPerformanceTabListHTML = (tabLabel, key, isDisabled = false) => {
    return (
      <li
        className={`${
          params?.nav === key ? 'active scrollable-tab' : 'scrollable-tab'
        } ${isDisabled ? 'disabled' : ''} ${
          hideSalesAndSponsoredTab &&
          (key === 'salePerformance' ||
            key === 'adPerformance' ||
            (key === 'creativeProjects' && hideCreativeProjectTab))
            ? 'd-none'
            : ''
        }`}
        onClick={() => {
          handlePerformanceTabClickEvents(key);
        }}
        role="presentation"
      >
        {tabLabel}
      </li>
    );
  };

  // display performance tab list
  const displayPerformanceTabList = () => {
    return (
      <Tabs className="mb-2 mb-md-3 scrollbar-outer">
        <ul className="tabs scrollable-container" id="scroll">
          {/* {!userInfo?.role?.includes('Customer') ||
              showSalesReportSpecificBP ? ( */}
          {displayPerformanceTabListHTML('Sales Performance', SALEPERFORMANCE)}
          {/* ) : null} */}
          {/* {!userInfo?.role?.includes('Customer') ||
              showAdperformanceSpecificBP ? ( */}
          {displayPerformanceTabListHTML(
            'Sponsored Ad Performance',
            ADPERFORMANCE,
          )}
          {displayPerformanceTabListHTML(
            'DSP Ad Performance',
            DSPPERFORMANCE,
            isDspDashboardDisabled,
          )}
          {/* ) : null} */}
          {/* {!userInfo?.role?.includes('Customer') ? ( */}
          {/* AMC Insights Tab */}
          {displayPerformanceTabListHTML('AMC Insights', AMCINSIGHTS)}
          {/* ) : null} */}
          {/* creative project tab */}
          {!userInfo?.role?.includes('Customer') &&
          customer?.account_type !== PARENT
            ? displayPerformanceTabListHTML(
                'Creative Projects',
                CREATIVEPROJECTS,
                hideCreativeProjectTab,
              )
            : null}
        </ul>
      </Tabs>
    );
  };

  // display refresh button
  const displayRefreshButton = () => {
    return (
      <RefreshButton className={isDisabledRefreshBtn ? 'disabled' : ''}>
        <div
          className="refresh-icon cursor"
          role="presentation"
          onClick={() => resetCreativeFilters()}
        >
          <FontAwesomeIcon icon="fa-light fa-arrows-rotate" fontSize="12px" />
        </div>
      </RefreshButton>
    );
  };

  // display performance tab list - (sales, sposnored, dsp, amc , creative project) and buttons
  const displayPerformanceTabListAndButtons = () => {
    return (
      <div className="row">
        <div
          className={` ${
            showRefreshBtn ? 'col-lg-8 col-md-11 col-12 pr-0' : 'col-12'
          }`}
        >
          {displayPerformanceTabList()}
        </div>

        <div
          className={` ${
            showRefreshBtn
              ? 'col-lg-4 col-md-1 col-12 pl-md-0 mb-2 mb-md-0'
              : 'col-12'
          }`}
        >
          {showRefreshBtn ? (
            <AmcReports>
              <ul>
                <li className="refersh-button">{displayRefreshButton()}</li>
              </ul>
            </AmcReports>
          ) : null}
        </div>
      </div>
    );
  };

  /* ********** main return statement of this component ********** */
  return (
    <div
      className="col-12"
      key={selectedAccountType?.value}
      style={{ position: 'relative' }}
    >
      {showCreativeUploadScreen ? (
        <UploadCreativeAsins
          id={id}
          setShowCreativeUploadScreen={setShowCreativeUploadScreen}
          accountType={selectedAccountType?.value}
          type="dataPresent"
        />
      ) : (
        <>
          {displayPerformanceTabListAndButtons()}
          {displayPerformanceTabComponents()}
        </>
      )}
    </div>
  );
}

CompanyPerformance.defaultProps = {
  marketplaceChoices: [],
  id: '',
  memberData: [],
  // showAdperformanceSpecificBP: false,
  //   showSalesReportSpecificBP: false,
  childBPList: [],
  isBBEInternalUser: 'internal',
  setShowCreativeUploadScreen: () => {},
  showCreativeUploadScreen: false,
};

CompanyPerformance.propTypes = {
  marketplaceChoices: arrayOf(shape({})),
  id: string,
  memberData: arrayOf(shape({})),
  // showAdperformanceSpecificBP: bool,
  // showSalesReportSpecificBP: bool,
  customer: shape({
    id: string,
  }).isRequired,
  childBPList: arrayOf(shape({})),
  isBBEInternalUser: string,
  setShowCreativeUploadScreen: func,
  showCreativeUploadScreen: bool,
};

const AmcReports = styled.div`
  margin: 5px 0 0 0;
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      display: inline-block;
      padding: 0 0 5px 0;
    }
  }

  @media only screen and (min-width: 768px) {
    text-align: right;
    border-bottom: 1px solid #e0e6e8;
  }
`;
