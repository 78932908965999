const creationOfInstanceTableHeaders = [
  {
    label: 'Instance Name',
    type: 'text',
    key: 'instance_name',
    isMandatory: true,
    request_type: 'created',
  },
  {
    label: 'Advertiser Name',
    type: 'text',
    key: 'customer_canonical_name',
    isMandatory: true,
  },
  {
    label: 'Marketplace ID',
    type: 'text',
    key: 'marketplace_id',
    isMandatory: true,
    request_type: 'created',
  },
  {
    label: 'Advertiser ID',
    type: 'text',
    key: 'advertiser_id',
    isMandatory: true,
    request_type: 'created',
  },
  {
    label: 'Created Time',
    type: 'date',
    key: 'created_at',
    request_type: 'created',
    info: 'Date and Time will be converted in MST timezone.',
  },
  {
    label: 'Status',
    type: 'status',
    key: 'creation_status',
    request_type: 'created',
  },
];

const updatingOfInstance = [
  { label: 'AWS ID', type: 'text', key: 'aws_id', isMandatory: true },
  { label: 'Instance ID', type: 'text', key: 'instance_id', isMandatory: true },
  {
    label: 'Marketplace ID',
    type: 'text',
    key: 'marketplace_id',
    isMandatory: true,
  },
  {
    label: 'Instance Name',
    type: 'text',
    key: 'instance_name',
    isMandatory: true,
  },
  {
    label: 'Advertiser Name',
    type: 'text',
    key: 'customer_canonical_name',
    isMandatory: true,
  },
  {
    label: 'Created Time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'creation_status' },
];

const deletionOfInstance = [
  { label: 'Instance ID', type: 'text', key: 'instance_id', isMandatory: true },
  {
    label: 'Marketplace ID',
    type: 'text',
    key: 'marketplace_id',
    isMandatory: true,
  },
  {
    label: 'Advertiser ID',
    type: 'text',
    key: 'advertiser_id',
    isMandatory: true,
  },
  {
    label: 'created Time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'creation_status' },
];

const additionOfAdvertiser = [
  {
    label: 'Instance Name',
    type: 'text',
    key: 'instance_name',
    isMandatory: true,
  },
  {
    label: 'Instance ID',
    type: 'text',
    key: 'instance_id',
    isMandatory: true,
  },
  { label: 'Entity ID', type: 'text', key: 'entity_id', isMandatory: true },
  {
    label: 'Advertiser ID',
    type: 'text',
    key: 'advertiser_id',
    isMandatory: true,
  },
  { label: 'Type', type: 'text', key: 'advertiser_type', isMandatory: true },
  {
    label: 'Marketplace ID',
    type: 'text',
    key: 'marketplace_id',
    isMandatory: true,
  },
  {
    label: 'Created Time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'creation_status' },
];

const deletionOfAdvertiser = [
  {
    label: 'Instance Name',
    type: 'text',
    key: 'instance_name',
    isMandatory: true,
  },
  {
    label: 'Instance ID',
    type: 'text',
    key: 'instance_id',
    isMandatory: true,
  },
  { label: 'Entity ID', type: 'text', key: 'entity_id', isMandatory: true },
  {
    label: 'Advertiser ID',
    type: 'text',
    key: 'advertiser_id',
    isMandatory: true,
  },
  { label: 'Type', type: 'text', key: 'advertiser_type', isMandatory: true },
  {
    label: 'Marketplace ID',
    type: 'text',
    key: 'marketplace_id',
    isMandatory: true,
  },
  {
    label: 'Created time',
    type: 'date',
    key: 'created_at',
    info: 'Date and Time will be converted in MST timezone.',
  },
  { label: 'Status', type: 'status', key: 'creation_status' },
];

const requestTypeData = [
  {
    label: 'Creation of Instance',
    key: 'create-instance',
    tableHeader: creationOfInstanceTableHeaders,
    tableData: [],
    requestType: 'created',
  },
  {
    label: 'Updating of Instance',
    key: 'update-instance',
    tableHeader: updatingOfInstance,
    tableData: [],
    requestType: 'updated',
  },
  {
    label: 'Deletion of Instance',
    key: 'delete-instance',
    tableHeader: deletionOfInstance,
    tableData: [],
    requestType: 'deleted',
  },
  {
    label: 'Adding of Advertiser',
    key: 'add-advertiser',
    tableHeader: additionOfAdvertiser,
    tableData: [],
    requestType: 'added',
  },
  {
    label: 'Deletion of Advertiser',
    key: 'delete-advertiser',
    tableHeader: deletionOfAdvertiser,
    tableData: [],
    requestType: 'deleted',
  },
];

const defaultCreateInstanceData = {
  instance_name: '',
  customer_canonical_name: '',
  marketplace_id: '',
  advertiser_id: '',
  created_at: '-',
  status: '-',
  type: 'create-new',
};

const defaultUpdateInstanceData = {
  aws_id: '',
  instance_id: '',
  instance_name: '',
  marketplace_id: '',
  customer_canonical_name: '',
  created_at: '-',
  status: '-',
  type: 'update-new',
};
const defaultDeleteInstanceData = {
  instance_id: '',
  marketplace_id: '',
  advertiser_id: '',
  created_at: '-',
  status: '-',
  type: 'delete-new',
};
const defaultCreateAdvertiserData = {
  instance_name: '',
  instance_id: '',
  entity_id: '',
  advertiser_id: '',
  advertiser_type: '',
  marketplace_id: '',
  created_at: '',
  status: '',
  type: 'ad-create-new',
};
const defaultDeleteAdvertiserData = {
  instance_name: '',
  instance_id: '',
  entity_id: '',
  advertiser_id: '',
  advertiser_type: '',
  marketplace_id: '',
  created_at: '',
  status: '',
  type: 'ad-delete-new',
};
export {
  defaultCreateInstanceData,
  defaultUpdateInstanceData,
  defaultDeleteInstanceData,
  defaultCreateAdvertiserData,
  defaultDeleteAdvertiserData,
  requestTypeData,
};
