import styled from 'styled-components';
import Theme from '../../../../../theme/Theme';

const AMCSelfService = styled.div`
  .bg-orange-light {
    color: ${Theme?.orange};
    font-size: ${Theme?.extraNormal};
    text-transform: uppercase;
    font-family: ${Theme?.titleFontFamily} !important;
    background: ${Theme.lightOrange};
    padding: 10px;
    margin-bottom: 10px;

    /* td {
      color: ${Theme.orange};
      background: ${Theme.lightOrange};
      text-transform: uppercase;
      font-size: ${Theme.extraNormal};
      font-family: ${Theme.titleFontFamily} !important;

      &.sticky-collapse {
        position: sticky;
        left: 0;
        z-index: 9;
      }
    } */
  }
  .add-new-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export default AMCSelfService;
