// Auth
export const API_LOGIN = '/login';
export const API_FORGOT_PASSWORD = '/request-password-reset/';
export const API_RESET_PASSWORD = '/reset-password/';
export const API_USER_ME = '/user/me/';
export const API_LOGOUT = '/logout';
export const API_USER = '/user/';
export const API_UPDATE_PASSWORD = '/change-password/';
export const API_CUSTOMER_NAMES = '/get-customers';
export const API_REQUEST_DEMO = '/request-demo/';
export const JIRA_TICKET_BASE_URL = 'https://bbe.atlassian.net/browse/';

// Customer
export const API_CUSTOMER = '/customer/';
export const API_CHOICES = '/choice/';
export const API_DOCUMENTS = '/document/';
export const API_CUSTOMER_CONTRACT = '/contract/';
export const API_CUSTOMER_MEMBER = '/customer-user/';
export const API_ACTIVITY_LOG = '/activity-log/';
export const API_CUSTOMER_ACTIVITY_LOG = '/customer_history/';
export const API_CONTACT = '/contact/';
export const API_CREDENTIALS = '/credentials/';
export const API_BGS = '/bgs/';
export const API_AMAZON_DETAILS = '/merchant/';
export const API_DELETE_MARKETPLACE = `${API_AMAZON_DETAILS}delete-marketplace/`;
export const API_PERFORMANCE = '/performance/';
export const API_VENDOR_ORDERED = '/performance-ordered-revenue/';
export const API_VENDOR_SHIPPED = '/performance-shipped-cogs/';
export const API_SP = '/lwa/';
export const API_GET_SP_DATA = `${API_SP}authorize-lwa/`;
export const API_CUSTOMER_FEEDBACK = '/onboarding-survey/';
export const API_MONKEY_SURVEY_FEEDBACK = '/survey/';
export const API_PERFORMANCE_MIN_DATE = '/performance-min-date/';
export const API_CHILD_BPS = `${API_CUSTOMER}child-bps/`;

// marketplace api constants
// export const API_PERFORMANCE = '/sales-performance/';
export const API_MARKETPLACES = '/marketplaces/';
export const API_ALL_MARKETPLACES = `${API_MARKETPLACES}all-marketplace/`;
export const API_GOAL_ALL_MARKETPLACES = `${API_MARKETPLACES}goal-marketplaces/`;
export const API_DSP_MARKETPLACES = '/marketplaces/dsp-marketplaces/';

// performance api constant
export const API_AD_PERFORMANCE = '/performance-metrics/';
export const API_ALLOCATE_BALANCE = `${API_AD_PERFORMANCE}allocate-balance/`;
export const API_ESCROW_BALANCE_MARKETPLACE = `${API_AD_PERFORMANCE}get-escrow-balance-marketplace/`;
export const API_ESCROW_REALLOCATION = '/escrow-reallocation/';
export const API_ADM = '/manager-list/';
export const API_SERVICES_FEE = '/type-of-services/';
export const API_ACCOUNT_MARKETPLACE = `${API_CUSTOMER}:id/marketplace/`;
export const API_ASIN_PERFORMANCE = '/asin-performance/';
export const API_SPONSORED_ASIN_PERFORMANCE =
  '/sp-advertising-asin-performance/';
export const API_SPONSORED_CAMPAIGN_DATA = '/sp-advertising-campaign/';
export const API_SPONSORED_CAMPAIGN_ASIN_PERFORMANCE =
  '/sp-advertising-campaign-asin/';
export const API_SP_CAMPAIGN_DOWNLOAD_REPORT =
  'sp-advertising-campaign-report/';
export const API_DSP_ASIN_PERFORMANCE = '/dsp-advertising-asin-performance/';
export const API_DSP_CAMPAIGN_LIST = '/dsp-advertising-campaign/';
export const API_DSP_CAMPAIGN_DOWNLOAD_REPORT =
  '/dsp-advertising-campaign-report/';
export const API_VENDOR_ORDERED_ASIN_PERFORMANCE =
  '/ordered-revenue-asin-performance/';
export const API_VENDOR_SHIPPED_COGS_ASIN_PERFORMANCE =
  '/shipped-cogs-asin-performance/';
export const API_ASIN_PERFORMANCE_DATA_COUNT = `${API_ASIN_PERFORMANCE}total-asin/`;
export const API_DATA_AVAIBALE_CHILD_BRANDS = `${API_PERFORMANCE}data-availability/`;
export const API_CREATIVE_PROJECT = '/creative-project/';
export const API_CREATIVE_PROJECT_TABLE_INFO = `${API_CREATIVE_PROJECT}table-info/`;
export const API_CREATIVE_PROJECT_MONTHS = `${API_CREATIVE_PROJECT}months/`;
export const API_CREATIVE_PROJECT_CSV = `${API_CREATIVE_PROJECT}table-csv/`;
export const API_PROJECT_TYPES = '/upload-project/';
export const API_COMPLETED_CREATIVE_PROJECT = '/upload-completed-project/';

// BP Proposal
export const API_BP_PROPOSAL = '/dsp-budget-proposal/';

// Inventory Reports
export const API_INVENTORY_RESTOCK_LIMITS = '/inventory-restock-limits/';
export const API_STRANDED_INVENTORY = '/stranded-inventory/';
export const API_RESTOCK_INVENTORY = '/restock-inventory/';
export const API_RESTOCK_REPORTS_DOWNLOAD = '/under-overstocked-reports/';
export const API_INVENTORY_RESTOCK_LIMITS_DASHBOARD =
  '/restock-limits-dashboard/';

// API Inventory - Estimated Lost Sales
export const API_ESTIMATED_LOST_SALES_INACTIVE =
  '/inactive-estimated-lost-sales/';
export const API_ESTIMATED_LOST_SALES_STRANDED =
  '/stranded-estimated-lost-sales/';
export const API_ESTIMATED_LOST_SALES_ARCHIVE =
  '/archive-estimated-lost-sales/';
export const API_ESTIMATED_LOST_SALES_REASON_LIST = `${API_ESTIMATED_LOST_SALES_STRANDED}reason/`;
export const API_ESTIMATED_LOST_SALES_ARCHIVE_RESTORE_ASIN =
  '/archive-estimated-lost-sales/';

// API Inventory - Long Term storage Fees
export const API_LONG_TERM_STORAGE_FEES = '/long-term-storage-fee/';

// Report Section API
export const API_REPORTS_TYPES = `${API_CHOICES}report-type`;
export const API_REPORTS_SECTION = '/bbe-reports/';
export const API_REPORTS_DOWNLOAD = '/bbe-reports/download-report/';
export const API_PARTNER_OPPORTUNITY_DROPDOWN_LIST = `${API_REPORTS_SECTION}partner-opportunity/`;
export const API_CUSTOMIZED_REPORTS = '/custom-report/';
export const API_CUSTOMIZED_REPORTS_TEMPLATE = '/custom-report-template/';
export const API_CUSTOMIZED_REPORTS_ACCESS = '/custom-report/notify-creation/';
export const API_CUSTOMIZED_REPORTS_METRICS_DATA =
  '/performance-combined-reports/';
export const API_CUSTOM_REPORTS_NOTIFICATION = `${API_CUSTOMIZED_REPORTS}activity-notification/`;
export const API_CUSTOMISED_REPORT_STATUS = 'restrict-multiple-user/';

/* ********** Dashboard API constants ********** */
// leadership dashboard API constants
export const API_AD_MANAGER_ADMIN_DASHBOARD = '/manager-dashboard/';
export const API_AD_DASHBOARD = '/ad-dashboard/';
export const API_MIN_DATE_DASHBOARD = `${API_AD_DASHBOARD}min-date-leadership-dashboard/`;
export const API_LEADERSHIP_CUSTOMERS = '/leadership-customers/';
export const API_KEY_CONTRIBUTORS_DOWNLOAD_REPORT = `${API_AD_MANAGER_ADMIN_DASHBOARD}key-contributors-report/`;
export const API_GOAL_PERFORMANCE_DATA = `/goal-performance/get-metrics/`;
export const API_GOAL_TARGET_MSG = `/marketplaces/goal-target/`;
export const API_SALES_DASHBOARD = 'sales-dashboard/';
export const API_VENDOR_ORDERED_REVENUE_SALES_DASHBOARD = `${API_AD_DASHBOARD}ordered-revenue/`;
export const API_VENDOR_SHIPPED_COGS_SALES_DASHBOARD = `${API_AD_DASHBOARD}shipped-cogs/`;
export const API_INVENTORY_DASHBOARD = '/inventory-dashboard/';
export const API_PENDING_APPROVALS = '/dashboard/pending-approvals/';

// performance snapshot dashboard API constants
export const API_BBE_GOAL_DASHBOARD = 'bbe-goals-dashboard/';
export const API_BBE_GOAL_METRICS = `${API_BBE_GOAL_DASHBOARD}performance-snapshot/`;
export const API_BBE_GOAL_DASHBOARD_CONTRIBUTION = `${API_BBE_GOAL_DASHBOARD}contribution/`;

// commission dashboard API constants
export const API_BGS_COMMISSION = '/bgs-commission/';
export const API_BGS_COMMISSION_MATRICS = `${API_BGS_COMMISSION}top-matrics/`;
export const API_BGS_COMMISSION_INDIVIDUALS = `${API_BGS_COMMISSION}individuals/`;
export const API_BGS_COMMISSION_GROUP_BY_MANAGER = `${API_BGS_COMMISSION}group-by-manager/`;
export const API_BGS_COMMISSION_DETAILS = `${API_BGS_COMMISSION}details/`;

// Billing, Invoice & Budget
export const API_DSP_INVOICES = '/invoice/';
export const API_DSP_BILLING = '/bill/';
export const API_DSP_BUDGET_ADJUSTMENT = '/dsp-budget-adjustment/';
export const API_DSP_EMPTY_BUDGET_ADJUSTMENT = `${API_DSP_BUDGET_ADJUSTMENT}empty-adjustment/`;
export const API_DSP_CONTACT = '/dsp-contact/';

// Playbook API Constants
export const API_CUSTOMER_PLAYBOOK = '/customer-playbook';
export const API_PLAYBOOK_QUARTER_DATA = `${API_CUSTOMER_PLAYBOOK}/get-playbook/`;
export const API_PLAYBOOK_SERVICES_LIST = `${API_CUSTOMER_PLAYBOOK}/get-service-list/`;
export const API_UPDATE_TASK_STATUS = `${API_CUSTOMER_PLAYBOOK}/update-status/`;
export const API_PLAYBOOK_CONTRACT_SERVICES = `${API_CUSTOMER_PLAYBOOK}/services/`;
export const API_CREATE_PLAYBOOK = `${API_CUSTOMER_PLAYBOOK}/create-playbook/`;
export const API_GET_ASSIGNEE = `${API_CUSTOMER_PLAYBOOK}/get-assignees/`;
export const API_UPDATE_ASSIGNEE = `${API_CUSTOMER_PLAYBOOK}/update-assignee/`;
export const API_ONBOARDING_PLAYBOOK_DATA = `${API_CUSTOMER_PLAYBOOK}/get-onboarding-playbook-task/`;
export const API_PLAYBOOK_TEAM = `${API_CUSTOMER_PLAYBOOK}/get-teams/`;
export const API_PLAYBOOK_SUMMARY = `${API_CUSTOMER_PLAYBOOK}/playbook-summary/`;
export const API_PLAYBOOK_SERVICES = `${API_CUSTOMER_PLAYBOOK}/approval-playbook-tasks/`;
export const API_SEND_FOR_APPROVAL = `${API_CUSTOMER_PLAYBOOK}/send-playbook-for-approval/`;

// Agreement
export const API_AGREEMENT_TEMPLATE = '/agreement-template/';
export const API_AGREEMENT_DETAILS = '/contract-details/';
export const API_MARKETPLACE = '/marketplaces/';
export const API_MARKETPLACE_BULK_UPDATE = `${API_MARKETPLACE}bulk-update/`;
export const API_ADDITIONAL_SERVICES = '/additional-services/';
export const API_ADDITIONAL_SERVICE_BULK_UPDATE = `${API_ADDITIONAL_SERVICES}bulk-update/`;
export const API_SEND_REMINDER = `${API_CUSTOMER_CONTRACT}send-reminder/`;
export const API_SIGNATURE_STATUS = `${API_CUSTOMER_CONTRACT}check-signature-status/`;
export const API_SERVICE_TYPE = `${API_SERVICES_FEE}service/`;
export const API_ADVERTSIING_SERVICES_OPTION = `/choice/amc-services`;
export const API_AMC_SERVICES_BULK_UPDATE = `/amc-services/bulk-update/`;
export const API_CONTRACT_DOCUMENT = `${API_CONTACT}contract-pdf/`;
export const API_CREATE_ADDENDUM = '/contract-data/';
export const API_TRANSACTION_DATA = '/transactional-data/';
export const API_CONTRACT_DESIGN = `${API_TRANSACTION_DATA}contract-design/`;
export const API_TRANSACTIONAL_SIGN_URL = `/sign-url/`;
export const API_CONTRACT_ACTIVITY_LOG = `/contract_history/`;
export const API_THRESHOLD_TYPE = `threshold-type`;
export const API_YOY_PERCENTAGE = `yoy-percentage`;
export const API_PAUSE_AGREEMENT = '/pause-contract/';
export const API_DISCOUNT = '/discount/';
export const API_FEE_STRUCTURE = '/contract-fee-structure/fee-data/';
export const API_SAVE_FEE_STRUCTURE = '/contract-fee-structure/';
export const API_STD_SERVICES = '/standard-us-services/bulk-update/';
export const API_STANDARD_NON_US_SERVICES_OPTIONS = `${API_CHOICES}standard-non-us-services`;
export const API_NON_US_MARKETPLACES = `${API_CHOICES}standard-non-us-service-marketplaces`;
export const API_STANDARD_NON_US_SERVICES_FREQUENCY = `${API_CHOICES}frequency`;
export const API_STANDARD_SERVICE_BULK_UPDATE = `standard-non-us-services/bulk-update/`;
export const API_SUSPEND_AGREEMENT = '/suspend-contract/';
export const API_DSP_AMOUNTS_PAUSE_AGREEMENT = '/suspend-contract/dsp-amount/';
export const API_DSP_MARKETPLACES_BULK_UPDATE = `dsp-marketplace/bulk-update/`;
export const API_DRAFT_ADDENDUM = '/contract-addendum/';
export const API_AGREEMENT_APPROVERS_USER = `${API_USER}request_approvers/`;

// Knowledge Base
export const API_KNOWLEDGE_BASE = '/knowledge/';
export const API_ARTICLE_COLLECTIONS = `${API_KNOWLEDGE_BASE}collections/`;
export const API_ARTICLE_BOARDS = `${API_KNOWLEDGE_BASE}folders/`;
export const API_ARTICLE_CARDS = `${API_KNOWLEDGE_BASE}cards/`;
export const API_ARTICLE_SEARCH = `${API_KNOWLEDGE_BASE}search/`;
export const API_UPDATE_ARTICLE = `${API_KNOWLEDGE_BASE}suggest-update/`;
export const API_CREATE_ARTICLE = `${API_KNOWLEDGE_BASE}suggest-idea/`;

// Onboarding Customer
export const API_ONBOARD_CUSTOMER = '/customer-onboarding/';
export const API_ACCOUNT_SUMMARY = `${API_ONBOARD_CUSTOMER}:id/account-summary/`;
export const API_STEPS_ASSIGNED = '/steps-assigned/';
export const API_VERIFY_TOKEN = `${API_STEPS_ASSIGNED}verify-token/`;
export const API_BILLING_INFO = '/billing-info/';
export const API_PAYMENT_TERMS = '/payment-terms/';
export const API_PAYMENT_TERMS_OPTIONS = `${API_CHOICES}payment-terms`;
export const API_SAVE_PAYMENT_TERMS = `${API_PAYMENT_TERMS}bulk-update/`;
export const API_VIDEO_LINKS = '/customer/:id/get-video-link/';
export const API_EDIT_EMAIL = `${API_STEPS_ASSIGNED}edit-email/`;
export const API_VERIFY_USER = `${API_USER}verify-user/`;
export const API_AMAZON_ACCOUNT_DETAILS = `${API_CUSTOMER}:id/account-step-details/`;
export const API_AMAZON_SELLER_ACCOUNT = '/seller-central/';
export const API_AMAZON_VENDOR_ACCOUNT = '/vendor-central/';
export const API_AMAZON_DSP_ACCOUNT = '/dsp-central/';
export const API_AMAZON_SELLER_CENTRAL_BULK_UPDATE = `${API_AMAZON_SELLER_ACCOUNT}bulk-create-or-update/`;
export const API_AMAZON_VENDOR_CENTRAL_BULK_UPDATE = `${API_AMAZON_VENDOR_ACCOUNT}bulk-create-or-update/`;
export const API_AMAZON_ACCOUNT = '/amazon-info/';
export const API_CUSTOMER_CSV = `${API_CUSTOMER}export-csv/`;

// Brand Assets
export const API_ACCOUNT_ASSIGNEE_COUNT = `${API_CUSTOMER}:id/account-setup-completion/`;
export const API_BRAND_ASSETS = '/brand-assets/';
export const API_BRAND_ASSETS_SUMMARY = `${API_BRAND_ASSETS}summary/`;
export const API_BRAND_ASSETS_COMMENTS = 'comments';

// Notes
export const API_NOTES = `/notes/`;

// Product Catalog
export const API_PRODUCT_CATALOG = '/products/';

// AMC Insights
export const API_AMC_INSIGHTS = 'amc/';
export const API_NTB_CUSTOMER = 'ntb-customer/';
export const API_TRIPLE_OVERLAP_VALUES = `${API_AMC_INSIGHTS}triple-overlap-value/`;
export const API_TRIPLE_OVERLAP_TABLE_VALUES = `${API_AMC_INSIGHTS}triple-overlap-value-table/`;
export const API_TRIPLE_OVERLAP_ANALYSIS = `${API_AMC_INSIGHTS}triple-overlap-analysis/`;
export const API_DSP_PPC_OVERLAP_ANALYSIS = `${API_AMC_INSIGHTS}double-overlap/`;

export const API_LIFE_TIME_VALUE = `amc-lifetime/value/`;
export const API_NTB_SALES_ANALYSIS = `ntb/analysis/`;
export const API_NTB_GEOGRAPHIC = `ntb-geographic/analysis/`;
export const API_NTB_GEOGRAPHIC_TABLE = `ntb-geographic/geography-table/`;

// aws quicksight dashboard api
export const API_GET_QUICKSIGHT_DASHBOARD_URL = 'qs-embedded-url';

// DSP Audience
export const API_DSP_AUDIENCE_QUERY = `query/`;
export const API_DSP_AUDIENCE = `dsp-audience/`;
export const API_DSP_ADVERTISER_DETAIL = `advertiser-detail/`;
export const API_DSP_ADVERTISER_DASHBOARD = `dsp-audience-dashboard/`;
export const API_BP_LIST_DSP_AUDIENCE_DASHBOARD = `dsp-audience-dashboard/bp-list/`;

// AMC Instance
export const API_AMC_INSTANCE = `amc-instance/`;
export const API_AMC_ADVERTISER = `amc-advertiser/`;

// AMC Dashboard BBE Summary
export const API_BBE_SUMMARY_DOUBLE_OVERLAP = `double-overlap-amc-summary/get-metrics/`;
export const API_BBE_SUMMARY_BP_LIST = `double-overlap-amc-summary/get-bp-list/`;

export const API_BBE_METRICS = `metrics-amc-summary/get-metrics/`;
export const API_BBE_NEW_TO_BRAND_ANALYSIS = `ntb-amc-summary/get-metrics/`;

// Off Boarding
export const API_BILLING_OFF_BOARDING_APPROVE_REJECT = `/suspend-contract`;

// Summary Dashboard PDV-5697
export const API_SUMMARY_DASHBOARD = '/summary-dashboard/';

// Goals
export const API_GOALS = '/goals/';
export const API_MILESTONE = '/milestone/';
export const API_DYNAMIC_ERRORS = '/dynamic-messages/';
export const API_GOALS_ACCOUNT_TYPE_FLAG = 'goal/goal-account-type/';
export const API_GOALS_REPORT = '/goal-report/';

// COS
export const API_COS = '/cos/';
export const API_COS_ASIN = '/asin/';

export const NON_AUTHORIZATION_APIS = [
  API_LOGIN,
  API_FORGOT_PASSWORD,
  API_RESET_PASSWORD,
  API_SIGNATURE_STATUS,
  API_ONBOARD_CUSTOMER,
  API_CUSTOMER_NAMES,
  API_VERIFY_USER,
  API_BRAND_ASSETS,
  API_REQUEST_DEMO,
];
