import styled from 'styled-components';

import Theme from '../../../theme/Theme';

export const CustomerDetailsBody = styled.div`
  background: ${Theme.gray6};
  min-height: 100%;
  width: 100%;
  position: relative;
  padding-left: ${(props) =>
    props.role.includes('Customer') ? '45px' : '45px'};
  padding-right: 45px;

  #sticky-row {
    position: sticky;
    width: 100%;
    z-index: 99;
    background: ${Theme.gray6};
    top: 60px;
    padding: 15px 0 5px 0;
  }
  .back-btn-link {
    color: ${Theme.gray85};
    font-size: ${Theme.extraNormal};
    text-transform: initial;
    cursor: pointer;
    display: contents;

    .left-arrow {
      width: 16px;
      margin-right: 5px;
      vertical-align: bottom;
    }
  }
  .brand-name.hide {
    display: none;
  }
  .brand-name.show {
    display: block;
  }
  .notes-btn {
    background: #f1f1f5;
    color: ${Theme.black};
    border: 0;
    border-radius: 8px;
    display: inline-block;
    height: 38px;
    width: 92px;
    padding: 0;
    img {
      vertical-align: middle;
      transform: rotate(90deg);
      width: 20px !important;
      height: 25px !important;
    }
  }
  .add-more-people {
    background-size: 100%;
    display: inline-block;
    vertical-align: top;

    img {
      border-radius: 50%;
      width: 40px;
      margin-left: -7px;
      height: 40px;
    }

    &.btn-add-team {
      background-color: ${Theme.white};
      border: 1px solid ${Theme.gray2};
      border-radius: 100%;
      width: 36px;
      margin-left: 2px;
      height: 36px;
      img {
        width: 15px;
        margin: -2px 9px 3px 2px;
      }
    }
  }

  .customer-body-container {
    max-width: 1220px;
    margin: 0 auto;
    width: 100%;
  }

  .account-type {
    float: none !important;
    margin: 0 auto;
  }

  .customer-dropdown-select {
    color: ${Theme.black};
    background-color: ${Theme.white};
    border-radius: 8px;
    width: 100%;
    padding: 10px 0;
    border-left: 3px solid ${Theme.orange};
    font-size: 16px;
    font-weight: bold;
    height: 55px;
    border-right: none;
    border-top: none;
    border-bottom: none;

    &:focus {
      outline: none;
    }

    .css-yk16xz-control {
      border: none;
      cursor: pointer;

      &:focus {
        outline: none;
      }
    }
    .css-1pahdxg-control {
      border: none !important;
      box-shadow: none !important;
      cursor: pointer;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }
    .css-26l3qy-menu {
      margin-top: -1px;
      border-radius: 4px;
      border-top: none;
      padding: 18px 0;
      border: none;
      box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.1);
      color: ${Theme.black};
      font-size: ${Theme.extraMedium};
      font-weight: 500;
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0 20px;

    .account-type {
      float: none !important;
      margin: unset;
    }
  }
  @media only screen and (max-width: 767px) {
    .account-type {
      float: none !important;
      margin: 0 auto;
    }
  }
`;
export const CustomerTabCollapse = styled.div`
  position: relative;
  padding: 30px 15px;
  transition: width 300ms ease-in-out;
  z-index: 9;
  background: ${Theme.white};
  height: 100%;
`;

export const CustomerTabList = styled.div`
  position: fixed;
  top: 140px;
  width: 100%;
  transition: width 200ms ease-in-out;
  .left-details-card {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 20px;

      .left-details {
        color: ${Theme.black};
        padding: 3px 0px 3px 11px;
        border-left: 3px solid ${Theme.white};
        display: flex;
        .customer-details-tab-icon {
          color: ${Theme.gray90};
          font-size: 22px;
          margin-right: 12px;
          width: 28px;
          vertical-align: middle;
        }
        .collapse-arrow-down-icon {
          position: absolute;
          right: 0;
        }
        &:hover {
          border-left: 3px solid ${Theme.orange};
          cursor: pointer;
          font-family: ${Theme.titleFontFamily};
        }
        &.active {
          border-left: 3px solid ${Theme.orange};
          cursor: pointer;
          font-family: ${Theme.titleFontFamily};
        }

        .tab-label,
        .collapse-icon {
          display: none;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .sub-category {
        display: none;
        padding: 0;
        margin: 0;
        list-style-type: none;

        li.sub-category-details {
          font-size: ${Theme.normal};
          padding: 25px 0 0 68px;
          color: ${Theme.black};
          margin: 0;

          &.active {
            font-weight: bold;
          }

          &:hover {
            cursor: pointer;
            font-family: ${Theme.titleFontFamily};
          }
        }
      }
    }

    &.active li {
      .sub-category {
        display: block;
      }
    }
  }
  .hamburger-menu {
    position: relative;
    margin: 0 0 15px 12px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    background: ${Theme.lightOrange};
  }
  .hamburger-menu div {
    display: block;
    margin: 0;
    width: 16px;
    height: 2px;
    background: ${Theme.black};
    transition: 0.5s;
    z-index: 99999;
    transform-origin: 11.5px -0.5px;
    cursor: pointer;
    position: relative;
    top: 12px;
    left: 8px;
  }
  .hamburger-menu div:first-child {
    transform-origin: 0% 0%;
    top: 12px;
  }
  .hamburger-menu div:nth-last-child(2) {
    transform-origin: 60% 100%;
    top: 18px;
  }
`;

export const NotesSideBar = styled.div`
  top: 0;
  background: #ffff;
  height: 100%;
  .footer-sticky {
    position: fixed;
    bottom: 0;
    max-width: 600px;
    width: 100%;
    background: white;
  }
  .notes-pin-unpin {
    position: relative;

    .pin-icon {
      background: #0062ff;
      padding: 2px;
      border-radius: 50%;
      width: 19px;
      position: absolute;
      top: 27px;
      left: 25px;
      transform: rotate(-46deg);
    }
  }
  .chat-info-icon {
    position: absolute;
    right: 47px;
  }
  .dropdown-select-all-notes {
    background-color: rgba(224, 231, 255, 0.2);
    border: 1px solid ${Theme.gray2};
    border-radius: 20px;
    width: 230px;
    height: 40px;
    color: ${Theme.black};
    padding: 11px 2px 0 14px;
  }
  .dropdown-notes-filter {
    background-color: ${Theme.white};
    border-radius: 8px;
    box-shadow: 0 5px 15px 0 rgba(68, 68, 79, 0.4);
    max-width: 230px;
    padding: 15px;
    position: absolute;
    z-index: 99999;
    top: 45px;
    width: 100%;

    &.hide {
      display: none;
    }
    &.show {
      display: block;
    }
    .notes-option {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        padding-bottom: 14px;

        &.checkbox-option {
          padding-bottom: 4px;
        }

        &.teams-title {
          color: ${Theme.gray40};
          text-transform: uppercase;
          font-size: 11px;
          padding: 5px 0 15px 0;
          font-family: ${Theme.titleFontFamily};
        }
      }
    }
  }
  .commemt-inbox-body {
    height: 80vh;
    overflow: scroll;
    padding-bottom: 50px;
  }
  @media only screen and (max-width: 767px) {
    .dropdown-select-all-notes {
      width: 100%;
      max-width: 100%;
    }
    .commemt-inbox-body {
      height: 60vh;
      overflow: scroll;
    }
  }
`;

export const BrandHeaderOption = styled.div`
  width: 100%;
  margin: 0 auto;
  .icons-row {
    text-align: center;
    margin-top: -38px;
    li {
      width: 32px;
      height: 32px;
      background: ${Theme.white};
      border-radius: 16px;
      border: 1px solid ${Theme.gray11};
      text-align: center;
      margin-right: 4px;
      cursor: default;
      padding: 5px;
      margin-bottom: 16px;
      &:last-child {
        margin-right: 0;
      }

      .icons-option {
        font-size: ${Theme.extraSmall};
        color: ${Theme.black};
      }
      &:hover {
        border: 1px solid ${Theme.orange};
        .icons-option {
          color: ${Theme.orange};
        }
      }
    }
  }
  .brand-status {
    li {
      background: ${Theme.gray8};
      border-radius: 12px;
      font-size: ${Theme.extraSmall};
      color: ${Theme.black};
      padding: 4px 13px;
      font-family: ${Theme.baseMediumFontFamily};
      margin-right: 4px;
      cursor: default;
      margin-bottom: 10px;
      border: 1px solid ${Theme.gray11};
      &.child-count {
        background: ${Theme.white};
      }

      .brand-name-icon {
        color: ${Theme.black};
      }

      &.sp-auth {
        background: none;
        border: none;
        padding: 0;
        vertical-align: bottom;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        color: ${Theme.orange};

        .brand-name-icon {
          color: ${Theme.orange};
        }
      }
    }
  }
  .brand-logo-details {
    position: relative;
    text-align: center;
    margin: 0 auto;

    .brand-logo-image {
      border: 5px solid ${Theme.white};
      border-radius: 12px;
      width: 98px;
      height: 98px;
    }
    .brand-name {
      color: ${Theme.black};
      font-size: ${Theme.subTitle};
      font-family: ${Theme.baseMediumFontFamily};
      margin-bottom: 10px;
    }

    .option-label {
      color: ${Theme.black};
      font-size: ${Theme.extraSmall};
      font-family: ${Theme.baseMediumFontFamily};
      text-align: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 80px;
      position: relative;
    }
    .extra-link-option {
      position: absolute;
      top: -2px;
      right: 0;
      width: fit-content;
      left: 0;
      padding-left: 90px;
    }
    .option-sub-title {
      color: ${Theme.gray85};
      font-size: ${Theme.labelSmall};
      white-space: nowrap;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 85px;
    }
    .company-status {
      background: ${Theme.darkGreen};
      border-radius: 19px;
      padding: 3px 10px;
      color: ${Theme.white};
      font-size: ${Theme.extraNormal};
      margin: 0 auto;
      top: 0;
      max-width: 137px;
      position: relative;

      &.inactive {
        background-color: ${Theme.gray60};
      }
      &.pending {
        background-color: ${Theme.darkYellow};
      }
      &.risk {
        background-color: #d63649;
      }

      img {
        vertical-align: middle;
        width: 17px;
        margin-left: 4px;
        margin-bottom: 2px;
      }
    }
    .company-label-info {
      font-size: ${Theme.normal};
      color: ${Theme.black};
      line-height: 22px;

      &:first-child {
        margin-left: 0;
      }
    }

    @media screen and (min-width: 768px) {
      display: flex;
      justify-content: center;
      max-width: 500px;
      .brand-logo-image {
        margin-left: -100px;
      }
    }
    @media screen and (min-width: 992px) {
      max-width: 600px;
    }
  }
`;

export const InputDateDropDown = styled.div`
  input {
    border: 1px solid #e0e7ff;
    border-radius: 5px;
    background: #f9faff;
    min-height: 40px;
    color: #000000;
    padding: 10px;
    max-width: 110px;
    width: 100%;

    &::placeholder {
      color: ${Theme.gray35};
      font-weight: 500;

      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }
  &.last_service_date {
    input {
      max-width: 100% !important;
    }
  }
`;
export const EditInput = styled.div`
  .external-edit {
    width: 14px;
    position: absolute;
    top: 12px;
  }
`;
