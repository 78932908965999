import React, { memo } from 'react';

import ReactTooltip from 'react-tooltip';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { func, number, shape } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AMCSelfServiceMobileView from './AMCSelfServiceMobileView';
import { InfoRedIcon, InfoIcons } from '../../../../../theme/images';
import { saveAMCInstance, saveAMCAdvertiser } from '../../../../../api';
import { TaskStatus } from '../../../../Customer/CustomerDetailView/Playbook/PlaybookStyle';
import {
  DesktopTable,
  CommonPagination,
  Button,
  InputFormField,
  PageLoader,
  NoData,
} from '../../../../../common';

function AMCSelfServiceTable({
  amcInstances,
  addNewRow,
  handleInputChange,
  deleteInstance,
  getAMCInstanceData,
  amcInstanceCount,
  pageNumber,
  setPageNumber,
  loader,
  setLoader,
  checkAllMandatoryFieldsFilled,
  setIsEditModeOn,
}) {
  const { tableData, tableHeader } = amcInstances;
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const isDesktop = useMediaQuery({ minWidth: 767 });

  // render progress label className
  const renderProgressLabelClassName = (status) => {
    const classesData = {
      started: 'progress-label gray-label',
      requested: 'progress-label gray-label',
      running: 'progress-label orange-label',
      in_progress: 'progress-label orange-label',
      updating: 'progress-label orange-label',
      pending: 'progress-label yellow-label',
      submitted: 'progress-label yellow-label',
      failed: 'progress-label red-label',
      rejected: 'progress-label red-label',
      successful: 'progress-label green-label',
      completed: 'progress-label green-label',
      deleted: 'progress-label green-label',
    };
    const lowerCaseStatus = status?.toLowerCase();
    return classesData?.[lowerCaseStatus];
  };

  // display tooltip data
  const displayTooltipData = (dataTip, dataFor, imgIcon) => {
    return (
      <>
        <img
          className="ml-1 cursor"
          style={{
            verticalAlign: 'text-bottom',
            fontWeight: '300',
          }}
          width="14px"
          src={imgIcon}
          alt="info"
          data-tip={dataTip}
          data-for={dataFor}
        />
        <ReactTooltip
          id={dataFor}
          aria-haspopup="true"
          place="top"
          effect="solid"
          html
        />
      </>
    );
  };

  // display status with proper styling(color)
  const displayStatus = (statusData, rowIndex) => {
    return (
      <TaskStatus
        className={`${renderProgressLabelClassName(
          statusData?.creation_status,
        )} `}
        role="presentation"
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        <div>
          {statusData?.creation_status || '-'}
          {statusData?.creation_status === 'FAILED' ? (
            <>
              {displayTooltipData(
                statusData?.failed_reason,
                `${statusData?.creation_status}${rowIndex}`,
                InfoRedIcon,
              )}
            </>
          ) : (
            ''
          )}
        </div>
      </TaskStatus>
    );
  };

  // function which return date in MST time format to display in table
  const getMSTTime = (value) => {
    const dateSplitterarray = value?.split('T');
    const timeSplitterarray = dateSplitterarray[1]?.split('-');
    let timeValue = timeSplitterarray?.[0];
    if (timeSplitterarray?.[0]?.includes('.')) {
      timeValue = timeSplitterarray?.[0]?.split('.')?.[0];
    }
    return `${dateSplitterarray?.[0]} T ${timeValue}`;
  };

  // created at column value display using this function
  const displayCreatedAtData = (item, header) => {
    return (
      <>
        <div>{getMSTTime(item?.[header?.key])}</div>
        {header?.key === 'created_at' ? (
          <div className="dsp-audience-createdBy-user">
            <FontAwesomeIcon
              icon="fa-light fa-file-contract"
              className="add-icon ml-2 mr-2"
            />
            created by {item?.user_details?.[0]?.first_name}{' '}
          </div>
        ) : (
          ''
        )}
      </>
    );
  };

  // function used to display values in table
  const displayValue = (item, header) => {
    switch (header?.key) {
      case 'creation_status':
        return displayStatus(item);
      case 'created_at':
        return displayCreatedAtData(item, header);

      default:
        return item[header?.key];
    }
  };

  // if value entered for entity id then disable advertiser_id fields and vice versa
  const checkAdvertiserFieldsToDisable = (instanceData, header) => {
    if (['add-advertiser', 'delete-advertiser'].includes(amcInstances?.key)) {
      if (instanceData?.entity_id && header?.key === 'advertiser_id') {
        return true;
      }
      if (instanceData?.advertiser_id && header?.key === 'entity_id') {
        return true;
      }
    }
    return false;
  };

  // function to show input boxes when new row added in table
  const displayInputField = (item, header, tableDataIndex) => {
    switch (header?.key) {
      case 'creation_status':
        return (
          <>
            <div className="float-left">-</div>
            <div className="float-right">
              <FontAwesomeIcon
                onClick={() => {
                  deleteInstance(tableDataIndex, amcInstances);
                }}
                icon="fa-regular fa-trash"
                style={{
                  color: '#d63649',
                  fontSize: '14px',
                }}
                className="cursor pl-1"
              />
            </div>
            <div className="clear" />
          </>
        );
      case 'created_at':
        return <div>-</div>;

      default:
        return (
          <InputFormField backgroundTransparent="transparent">
            <label htmlFor={`${header?.key}-${tableDataIndex}`}>
              <input
                className={`form-control amc-instance-table mt-0 ${
                  checkAdvertiserFieldsToDisable(item, header) ||
                  header?.key === 'advertiser_type'
                    ? 'disabled'
                    : ''
                }`}
                type="text"
                id={`${header?.key}-${tableDataIndex}`}
                placeholder={`Enter ${header?.label}`}
                name={`${header?.key}`}
                defaultValue={item?.[header?.key]}
                maxLength={100}
                disabled={checkAdvertiserFieldsToDisable(item, header)}
                onChange={(event) => {
                  handleInputChange(event, amcInstances, tableDataIndex);
                }}
              />
            </label>
          </InputFormField>
        );
    }
  };

  // function to show table
  const displayTable = (tableHeaders) => {
    return (
      <DesktopTable className="metrics-table" style={{ tableLayout: 'fixed' }}>
        <thead>
          <tr>
            {tableHeaders?.map((item) => {
              return (
                <th
                  key={item?.key}
                  style={{ width: item?.width }}
                  colSpan={item?.colSpan}
                >
                  {item?.label}
                  {item?.info
                    ? displayTooltipData(
                        item?.info,
                        `${item?.label}`,
                        InfoIcons,
                      )
                    : ''}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {tableData?.map((item, tableDataIndex) => {
            const rowKey = item?.type
              ? `${item.type}-${(tableDataIndex + 1)?.toString()}`
              : `${item?.id || ''}${item?.instance_id || ''}`;
            return (
              <tr key={rowKey} id={rowKey}>
                {tableHeaders?.map((header) => {
                  return (
                    <td className="amc-instance-td" key={header?.key}>
                      {item?.id
                        ? displayValue(item, header)
                        : displayInputField(item, header, tableDataIndex)}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </DesktopTable>
    );
  };

  // display Add New button
  const displayAddNewRowBtn = (requestType) => {
    return (
      <div className="sticky-column add-new-cos-btn ">
        <Button
          className="btn-add-contact extraNormal-text"
          onClick={() => {
            addNewRow(requestType);
          }}
          style={{ textTransform: 'uppercase', minHeight: '0' }}
        >
          <FontAwesomeIcon
            color="#FF5933"
            fontSize="12px"
            icon="fa-regular fa-plus"
          />{' '}
          Add Row
        </Button>
      </div>
    );
  };

  const saveData = () => {
    const data = amcInstances?.tableData
      ?.filter((item) => !item.id)
      ?.map((filteredData) => {
        filteredData.request_type = amcInstances?.requestType;
        delete filteredData.created_at;
        delete filteredData.status;
        delete filteredData.type;

        return filteredData;
      });

    setLoader({ type: 'button', show: true });
    setIsEditModeOn(false);

    let api = saveAMCInstance;

    if (['add-advertiser', 'delete-advertiser'].includes(amcInstances?.key)) {
      api = saveAMCAdvertiser;
    }
    api(data).then((res) => {
      if (res?.status === 201) {
        getAMCInstanceData(amcInstances?.requestType, pageNumber);
        toast.success('Data Added successfully!');
      }
      if (res?.status === 400) {
        toast.error(res?.data?.[0]?.amc_instance?.[0]);
      }
      setLoader({ type: 'button', show: false });
    });
  };

  const displayConfirmBtn = (requestType) => {
    return (
      <div className="sticky-column add-new-cos-btn">
        <Button
          type="button"
          data-tip={
            checkAllMandatoryFieldsFilled(amcInstances)
              ? null
              : 'All mandatory fields should be filled in to proceed.'
          }
          data-for="save-amc-instance"
          className={`btn-primary on-boarding extraNormal-text `}
          style={
            checkAllMandatoryFieldsFilled(amcInstances)
              ? {
                  width: 'fit-content',
                  borderRadius: '6px',
                  textTransform: 'uppercase',
                }
              : {
                  pointerEvents: 'all',
                  opacity: 0.6,
                  cursor: 'not-allowed',
                  width: 'fit-content',
                  textTransform: 'uppercase',
                }
          }
          onClick={() => {
            if (checkAllMandatoryFieldsFilled(amcInstances))
              saveData(requestType);
          }}
        >
          {loader?.show && loader?.type === 'button' ? (
            <PageLoader color="#FFFFFF" type="button" />
          ) : (
            'Confirm'
          )}
        </Button>
        {checkAllMandatoryFieldsFilled(amcInstances) ? null : (
          <ReactTooltip
            id="save-amc-instance"
            aria-haspopup="true"
            place="top"
          />
        )}
      </div>
    );
  };

  // Main return
  return (
    <>
      {tableData?.length ? (
        isDesktop ? (
          displayTable(tableHeader)
        ) : (
          <AMCSelfServiceMobileView
            tableData={tableData}
            tableHeader={tableHeader}
            displayValue={displayValue}
            displayInputField={displayInputField}
          />
        )
      ) : (
        <NoData>No Data Available</NoData>
      )}

      <CommonPagination
        count={amcInstanceCount?.count}
        pageNumber={pageNumber}
        handlePageChange={(currentPage) => setPageNumber(currentPage)}
      />
      {userInfo?.amc_instance ? (
        <div
          className={`add-new-row mb-4 ${
            amcInstanceCount?.count > 10 ? 'mt-2' : 'mt-4'
          }`}
        >
          {displayAddNewRowBtn(amcInstances?.key)}
          {amcInstances?.tableData?.filter((item) => !item.id)?.length
            ? displayConfirmBtn(amcInstances?.key)
            : null}
        </div>
      ) : null}
    </>
  );
}

export default memo(AMCSelfServiceTable);

AMCSelfServiceTable.defaultProps = {
  amcInstances: {},
  addNewRow: () => {},
  handleInputChange: () => {},
  deleteInstance: () => {},
  getAMCInstanceData: () => {},
  amcInstanceCount: {},
  pageNumber: 1,
  setPageNumber: () => {},
  loader: {},
  setLoader: () => {},
  checkAllMandatoryFieldsFilled: () => {},
  setIsEditModeOn: () => {},
};

AMCSelfServiceTable.propTypes = {
  amcInstances: shape({}),
  addNewRow: func,
  handleInputChange: func,
  deleteInstance: func,
  getAMCInstanceData: func,
  amcInstanceCount: shape({}),
  pageNumber: number,
  setPageNumber: func,
  loader: shape({}),
  setLoader: func,
  checkAllMandatoryFieldsFilled: func,
  setIsEditModeOn: func,
};
