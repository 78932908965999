import React, { useState } from 'react';

import { Collapse } from 'react-collapse';
import { func, shape, bool, arrayOf, string } from 'prop-types';

import {
  InputFormField,
  ErrorMsg,
  CheckBox,
  Button,
  Status,
} from '../../../common';

import {
  OrangeCheckMark,
  ForwardOrangeIcon,
  GreyClockIcon,
} from '../../../theme/images';
import {
  amazonSellerAccountDetails,
  amazonVendorAccountDetails,
} from '../../../constants';

const HybridAccountMarketplaces = ({
  accountDetails,
  mapSecondaryMarketplaceValues,
  setDefaultForSecondary,
  checkCentralDetails,
  sellerAdvertiseForMarketplace,
  vendorAdvertiseForMarketplace,
  sellerMarketplaces,
  vendorMarketplaces,
  apiError,
  mapApiErrors,
  isAuthorized,
  localSeller,
  localVendor,
  accountRedirectURL,
  isChecked,
}) => {
  const [sellerDetailsCollapse, setSellerDetailsCollapse] = useState(false);
  const [vendorDetailsCollapse, setVendorDetailsCollapse] = useState(false);
  const [doesntSellerAdvertise, setDoesntSellerAdvertise] = useState(
    sellerAdvertiseForMarketplace,
  );
  const [doesntVendorAdvertise, setDoesntVendorAdvertise] = useState(
    vendorAdvertiseForMarketplace,
  );

  // map secondary marketplaces Values of input field
  const mapMarketPlaceValues = (
    accountType,
    marketplaceID,
    marketplaceName,
    key,
  ) => {
    let marketplaceValue = null;
    let tempValue = null;
    if (accountType === 'Seller') {
      marketplaceValue = (
        localSeller?.length ? localSeller : sellerMarketplaces
      )?.find(
        (entry) =>
          entry?.marketplace === marketplaceID &&
          entry?.marketplace_name === marketplaceName,
      );
      tempValue = marketplaceValue?.[key];
    }

    if (accountType === 'Vendor') {
      marketplaceValue = (
        localVendor?.length ? localVendor : vendorMarketplaces
      )?.find(
        (entry) =>
          entry?.marketplace === marketplaceID &&
          entry?.marketplace_name === marketplaceName,
      );

      tempValue = marketplaceValue?.[key];
    }
    return tempValue === ' ' ? '' : tempValue;
  };

  const checkSuccessfulMsg = (type) => {
    if (
      typeof isAuthorized[accountDetails?.vendor_marketplace?.name?.label] ===
      'object'
    ) {
      return isAuthorized[accountDetails?.seller_marketplace?.name?.label]?.[
        type
      ];
    }

    if (type === 'seller')
      return isAuthorized[accountDetails?.seller_marketplace?.name?.label]?.[
        type
      ];
    return isAuthorized[accountDetails?.vendor_marketplace?.name?.label]?.[
      type
    ];
  };

  return (
    <>
      <fieldset className="shape-without-border mt-3">
        <div>
          <h6 className="small-title">{accountDetails.name}</h6>
          <ul className="amazon-merchant-collapse">
            {/* ---------Seller Central Details Collapse---------- */}
            {accountDetails?.seller_marketplace ? (
              <li className="mt-3">
                {' '}
                <div
                  onClick={() =>
                    setSellerDetailsCollapse(!sellerDetailsCollapse)
                  }
                  role="presentation"
                >
                  <div className="row cursor">
                    <div className="col-10">
                      <div className="completed-status ">
                        {checkCentralDetails(
                          'Seller',
                          accountDetails?.seller_marketplace?.id,
                          accountDetails?.seller_marketplace?.name?.value,
                        ) ? (
                          <img
                            width="18px"
                            className="orange-check-collapse mr-2"
                            src={OrangeCheckMark}
                            alt="check"
                          />
                        ) : (
                          <img
                            width="16px"
                            className="orange-check-collapse mr-2"
                            src={GreyClockIcon}
                            alt="check"
                          />
                        )}
                        Seller Central Details
                      </div>
                    </div>
                    <div className="col-2 text-right pr-2">
                      <img
                        width="18px"
                        className="orange-arrowdown-collapse mr-2"
                        src={ForwardOrangeIcon}
                        alt="check"
                        style={{
                          transform: sellerDetailsCollapse
                            ? 'rotate(270deg)'
                            : '',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Collapse
                  isOpened={sellerDetailsCollapse}
                  theme={{ content: 'seller-collapse' }}
                >
                  <p className="normal-text-grey">
                    Log into your Amazon Seller Central admin account for your
                    additional marketplace.
                    <br />
                    <span className=" open-link cursor">
                      {checkSuccessfulMsg('seller') ? (
                        <>
                          <Status
                            className=""
                            label="Authorization Successful."
                            backgroundColor="#e3f2d2"
                            icon
                            labelColor="#33ac2e"
                            width="100%"
                            marginBottom="10px"
                            marginTop="10px"
                            fontIcon="far fa-check"
                          />
                          <div className="horizontal-line straight-line mt-5" />
                        </>
                      ) : (
                        <>
                          <a
                            href={encodeURI(
                              `${accountDetails?.seller_marketplace?.url}/apps/authorize/consent?application_id=amzn1.sp.solution.103a85c5-769f-4523-829c-c3a3db9054b5&version=beta&redirect_uri=${accountRedirectURL}&state=5tgb6yhn7ujm`,
                            )}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            onClick={() =>
                              localStorage.setItem(
                                'spAPI',
                                JSON.stringify({
                                  type: 'seller',
                                  marketplace:
                                    accountDetails?.seller_marketplace?.name
                                      ?.value,
                                }),
                              )
                            }
                          >
                            <Button
                              className={
                                isChecked
                                  ? 'btn-primary font-style-regular w-100 mt-4 isDisabled'
                                  : 'btn-primary font-style-regular w-100 mt-4'
                              }
                            >
                              Log into Amazon Seller Central Account
                            </Button>
                          </a>
                          <div className="horizontal-line straight-line mt-3" />
                        </>
                      )}
                    </span>
                  </p>
                  <p className="normal-text-grey">
                    Navigate to Advertising &gt; Campaign Manager &gt; Access &
                    Settings and enter your <strong> Advertiser Name</strong>{' '}
                    and <strong>ID</strong> below.
                    <br />
                  </p>
                  {amazonSellerAccountDetails?.map((item) => (
                    <>
                      <InputFormField className="mt-4" key={item.key}>
                        <label htmlFor={item.label}>
                          {item.label}
                          <input
                            placeholder={`Enter ${item.label}`}
                            className="form-control"
                            id={`${item.key}_${accountDetails?.seller_marketplace?.name?.value}`}
                            onChange={(event) => {
                              setDefaultForSecondary(
                                item.key,
                                event.target.value,
                                accountDetails?.seller_marketplace?.id,
                                'Seller',
                                accountDetails?.seller_marketplace?.Seller
                                  ?.id ||
                                  localSeller?.filter((op) => op.id)?.[0]?.id,
                                accountDetails?.seller_marketplace?.name?.value,
                              );
                            }}
                            value={mapMarketPlaceValues(
                              'Seller',
                              accountDetails.seller_marketplace.id,
                              accountDetails?.seller_marketplace?.name?.value,
                              item.key,
                              item.label,
                            )}
                            defaultValue={mapSecondaryMarketplaceValues(
                              item.key,
                              accountDetails.seller_marketplace.id,
                              'Seller',
                              accountDetails?.seller_marketplace?.name?.value,
                            )}
                            readOnly={mapMarketPlaceValues(
                              'Seller',
                              accountDetails.seller_marketplace.id,
                              accountDetails?.seller_marketplace?.name?.value,
                              'doesnt_advertise',
                            )}
                          />
                        </label>
                        {Object.keys(apiError).length > 0 ? (
                          <>
                            <ErrorMsg>
                              {mapApiErrors(
                                item.key,
                                accountDetails?.seller_marketplace?.id,
                                'Seller',
                                accountDetails?.seller_marketplace?.name?.value,
                              )}
                            </ErrorMsg>
                          </>
                        ) : null}
                      </InputFormField>
                    </>
                  ))}
                  <CheckBox className="mt-3 mb-4">
                    <label
                      className="check-container customer-pannel"
                      htmlFor={`seller${accountDetails?.seller_marketplace?.name?.value}`}
                    >
                      I do not advertise on this marketplace
                      <input
                        type="checkbox"
                        id={`seller${accountDetails?.seller_marketplace?.name?.value}`}
                        name={`${accountDetails?.seller_marketplace?.name?.value}`}
                        onChange={() => {
                          setDoesntSellerAdvertise(!doesntSellerAdvertise);
                          setDefaultForSecondary(
                            'doesnt_advertise',
                            !doesntSellerAdvertise,
                            accountDetails.seller_marketplace.id,
                            'Seller',
                            accountDetails?.seller_marketplace?.Seller?.id ||
                              localSeller?.filter((op) => op.id)?.[0]?.id,
                            accountDetails?.seller_marketplace?.name?.value,
                          );
                        }}
                        defaultChecked={mapSecondaryMarketplaceValues(
                          'doesnt_advertise',
                          accountDetails.seller_marketplace.id,
                          'Seller',
                          accountDetails?.seller_marketplace?.name?.value,
                        )}
                      />
                      <span className="checkmark" />
                    </label>
                  </CheckBox>
                </Collapse>
              </li>
            ) : null}

            {/* ------------Divider Line between Seller & Vender------------ */}
            {accountDetails?.seller_marketplace &&
            accountDetails?.vendor_marketplace ? (
              <div className="straight-line horizontal-line  mt-3 " />
            ) : null}

            {/* ---------Vendor Central Details Collapse---------- */}
            {accountDetails?.vendor_marketplace ? (
              <li className="mt-3">
                {' '}
                <div
                  onClick={() =>
                    setVendorDetailsCollapse(!vendorDetailsCollapse)
                  }
                  role="presentation"
                >
                  <div className="row cursor">
                    <div className="col-10">
                      <div className="completed-status ">
                        {checkCentralDetails(
                          'Vendor',
                          accountDetails?.vendor_marketplace?.id,
                          accountDetails?.vendor_marketplace?.name?.value,
                        ) ? (
                          <img
                            width="18px"
                            className="orange-check-collapse mr-2"
                            src={OrangeCheckMark}
                            alt="check"
                          />
                        ) : (
                          <img
                            width="16px"
                            className="orange-check-collapse mr-2"
                            src={GreyClockIcon}
                            alt="check"
                          />
                        )}
                        Vendor Central Details
                      </div>
                    </div>
                    <div className="col-2 text-right pr-2">
                      <img
                        width="18px"
                        className="orange-arrowdown-collapse mr-2"
                        src={ForwardOrangeIcon}
                        alt="check"
                        style={{
                          transform: vendorDetailsCollapse
                            ? 'rotate(270deg)'
                            : '',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <Collapse
                  isOpened={vendorDetailsCollapse}
                  theme={{ content: 'vendor-collapse' }}
                >
                  <p className="normal-text-grey">
                    Log into your Amazon Vendor Central admin account for your
                    additional marketplace.
                    <br />
                    <span className=" open-link cursor">
                      {checkSuccessfulMsg('vendor') ? (
                        <>
                          <Status
                            className=""
                            label="Authorization Successful."
                            backgroundColor="#e3f2d2"
                            icon
                            labelColor="#33ac2e"
                            width="100%"
                            marginBottom="10px"
                            marginTop="10px"
                            fontIcon="far fa-check"
                          />
                          <div className="horizontal-line straight-line mt-5" />
                        </>
                      ) : (
                        <>
                          <a
                            href={encodeURI(
                              `${accountDetails?.vendor_marketplace?.url}/apps/authorize/consent?application_id=amzn1.sp.solution.103a85c5-769f-4523-829c-c3a3db9054b5&version=beta&redirect_uri=${accountRedirectURL}&state=5tgb6yhn7ujm`,
                            )}
                            // target="_blank"
                            // rel="noopener noreferrer"
                            onClick={() =>
                              localStorage.setItem(
                                'spAPI',
                                JSON.stringify({
                                  type: 'vendor',
                                  marketplace:
                                    accountDetails?.vendor_marketplace?.name
                                      ?.value,
                                }),
                              )
                            }
                          >
                            <Button
                              className={
                                isChecked
                                  ? 'btn-primary font-style-regular w-100 mt-4 isDisabled'
                                  : 'btn-primary font-style-regular w-100 mt-4'
                              }
                            >
                              Log into Amazon Vendor Central Account
                            </Button>
                          </a>
                          <div className="horizontal-line straight-line mt-3" />
                        </>
                      )}
                    </span>
                  </p>
                  <p className="normal-text-grey">
                    Navigate to Advertising &gt; Campaign Manager &gt; Access &
                    Settings and enter your <strong> Advertiser Name</strong>{' '}
                    and <strong>ID</strong> below.
                    <br />
                  </p>
                  {amazonVendorAccountDetails?.map((item) => (
                    <>
                      <InputFormField className="mt-3" key={item.key}>
                        <label htmlFor={item.label}>
                          {item.label}
                          <input
                            placeholder={`Enter ${item.label}`}
                            id={`${item.key}_${accountDetails?.vendor_marketplace?.name?.value}`}
                            className="form-control"
                            onChange={(event) => {
                              setDefaultForSecondary(
                                item.key,
                                event.target.value,
                                accountDetails.vendor_marketplace.id,
                                'Vendor',
                                accountDetails?.vendor_marketplace?.Vendor
                                  ?.id ||
                                  localVendor?.filter((op) => op.id)?.[0]?.id,
                                accountDetails?.vendor_marketplace?.name?.value,
                              );
                            }}
                            value={mapMarketPlaceValues(
                              'Vendor',
                              accountDetails.vendor_marketplace.id,
                              accountDetails?.vendor_marketplace?.name?.value,
                              item.key,
                              item.label,
                            )}
                            defaultValue={mapSecondaryMarketplaceValues(
                              item.key,
                              accountDetails.vendor_marketplace.id,
                              'Vendor',
                              accountDetails?.vendor_marketplace?.name?.value,
                            )}
                            readOnly={mapMarketPlaceValues(
                              'Vendor',
                              accountDetails?.vendor_marketplace?.id,
                              accountDetails?.vendor_marketplace?.name?.value,
                              'doesnt_advertise',
                            )}
                          />
                        </label>
                        {Object.keys(apiError).length > 0 ? (
                          <>
                            <ErrorMsg>
                              {mapApiErrors(
                                item.key,
                                accountDetails.vendor_marketplace.id,
                                'Vendor',
                                accountDetails?.vendor_marketplace?.name?.value,
                              )}
                            </ErrorMsg>
                          </>
                        ) : null}
                      </InputFormField>
                    </>
                  ))}
                  <CheckBox className="mt-3 mb-4">
                    <label
                      className="check-container customer-pannel"
                      htmlFor={`vendor${accountDetails?.vendor_marketplace?.name?.value}`}
                    >
                      I do not advertise on this marketplace
                      <input
                        type="checkbox"
                        id={`vendor${accountDetails?.vendor_marketplace?.name?.value}`}
                        name={`${accountDetails?.vendor_marketplace?.name?.value}`}
                        onChange={() => {
                          setDoesntVendorAdvertise(!doesntVendorAdvertise);
                          setDefaultForSecondary(
                            'doesnt_advertise',
                            !doesntVendorAdvertise,
                            accountDetails.vendor_marketplace.id,
                            'Vendor',
                            accountDetails?.vendor_marketplace?.Vendor?.id ||
                              localVendor?.filter((op) => op.id)?.[0]?.id,
                            accountDetails?.vendor_marketplace?.name?.value,
                          );
                        }}
                        defaultChecked={mapSecondaryMarketplaceValues(
                          'doesnt_advertise',
                          accountDetails?.vendor_marketplace?.id,
                          'Vendor',
                          accountDetails?.vendor_marketplace?.name?.value,
                        )}
                      />
                      <span className="checkmark" />
                    </label>
                  </CheckBox>
                </Collapse>
              </li>
            ) : null}
          </ul>
        </div>
      </fieldset>
    </>
  );
};

HybridAccountMarketplaces.propTypes = {
  accountDetails: shape({}).isRequired,
  mapSecondaryMarketplaceValues: func.isRequired,
  setDefaultForSecondary: func.isRequired,
  checkCentralDetails: func.isRequired,
  sellerAdvertiseForMarketplace: bool.isRequired,
  vendorAdvertiseForMarketplace: bool.isRequired,
  apiError: shape({}).isRequired,
  mapApiErrors: func.isRequired,
  sellerMarketplaces: arrayOf(shape({})).isRequired,
  vendorMarketplaces: arrayOf(shape({})).isRequired,
  isAuthorized: shape({}).isRequired,
  localSeller: shape([]).isRequired,
  localVendor: shape([]).isRequired,
  accountRedirectURL: string.isRequired,
  isChecked: bool.isRequired,
};

export default HybridAccountMarketplaces;
