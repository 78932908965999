import React, { useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, func } from 'prop-types';

import Theme from '../../../../../theme/Theme';

function AMCSelfServiceMobileView({
  tableHeader,
  tableData,
  displayValue,
  displayInputField,
}) {
  const [viewMoreDetailsFlag, setViewMoreDetailsFlag] = useState({
    index: null,
    flag: null,
  });

  const displayTableData = (item, tableDataIndex) => {
    if (
      viewMoreDetailsFlag?.index === tableDataIndex &&
      viewMoreDetailsFlag?.flag
    ) {
      return tableHeader?.map((header) => {
        return (
          <div className="col-6 mb-3" key={header?.label}>
            <div className="label mb-0" id={header?.key}>
              {header?.label}
            </div>
            <div className="normal-text-black text-medium">
              {item?.id
                ? displayValue(item, header)
                : displayInputField(item, header, tableDataIndex)}
            </div>
          </div>
        );
      });
    }

    return tableHeader.slice(0, 4)?.map((header) => {
      return (
        <div className="col-6 mb-3" key={header?.label}>
          <div className="label mb-0" id={header?.key}>
            {header?.label}
          </div>
          <div className="normal-text-black text-medium">
            {item?.id
              ? displayValue(item, header)
              : displayInputField(item, header, tableDataIndex)}
          </div>
        </div>
      );
    });
  };

  const displayTable = () => {
    return (
      <>
        {tableData?.map((item, tableDataIndex) => {
          const rowKey = item?.type
            ? `${item.type}-${(tableDataIndex + 1)?.toString()}`
            : `${item?.id || ''}${item?.instance_id || ''}`;
          return (
            <fieldset className="shape-without-border w-430 mt-2 mb-4">
              <div className="row" id={rowKey}>
                {displayTableData(item, tableDataIndex)}

                <div
                  className="capsule-collpase"
                  role="presentation"
                  onClick={() => {
                    setViewMoreDetailsFlag({
                      index: tableDataIndex,
                      flag: !viewMoreDetailsFlag.flag,
                    });
                  }}
                >
                  {viewMoreDetailsFlag?.index === tableDataIndex &&
                  viewMoreDetailsFlag?.flag
                    ? 'View less details'
                    : `View more details`}
                  <FontAwesomeIcon
                    icon="fa-regular fa-angle-down"
                    className="dropdown-arrow-icon"
                    style={{
                      transform:
                        viewMoreDetailsFlag?.index === tableDataIndex &&
                        viewMoreDetailsFlag?.flag
                          ? 'rotate(180deg)'
                          : '',
                      marginLeft: '10px',
                      verticalAlign: 'middle',
                    }}
                  />
                </div>
              </div>
            </fieldset>
          );
        })}
      </>
    );
  };

  return (
    <DSPAudienceMobileViewStyle>{displayTable()}</DSPAudienceMobileViewStyle>
  );
}

export default AMCSelfServiceMobileView;

AMCSelfServiceMobileView.defaultProps = {
  tableHeader: [],
  tableData: [],
  displayValue: () => {},
  displayInputField: () => {},
};

AMCSelfServiceMobileView.propTypes = {
  tableHeader: arrayOf(shape({})),
  tableData: arrayOf(shape({})),
  displayValue: func,
  displayInputField: func,
};

const DSPAudienceMobileViewStyle = styled.div`
  .shape-without-border {
    position: relative;
  }
  .capsule-collpase {
    border-radius: 20px;
    bottom: 0;
    border: 1px solid ${Theme.gray11};
    color: ${Theme.black};
    font-size: ${Theme.extraNormal};
    font-family: Noah-Medium;
    background: ${Theme.white};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 3px 12px;
    text-align: center;
    position: absolute;
    margin: -18px auto;
    right: 0;
    left: 0;
  }
  .asin-trash {
    padding-right: 5px;
  }

  .display-flex {
    display: flex;
  }
`;
