import React, { useState, useCallback, useEffect } from 'react';

import Modal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { string, shape, bool, func, arrayOf } from 'prop-types';

import Theme from '../../../theme/Theme';
import EditAccountDetails from './EditAccountDetails';
import { getCustomerDetails } from '../../../api';
import { BrandHeaderOption } from './CustomerDetailStyles';
import {
  DropDownSelect,
  DropDownStatus,
  ModalBox,
  DropdownIndicator,
  Status,
} from '../../../common';
import {
  AuthorizedSC,
  CloseIcon,
  UnauthorizedSC,
  AuthorizedVC,
  UnauthorizedVC,
  CompanyDefaultUser,
} from '../../../theme/images';
import {
  HYBRID,
  PARENT,
  PATH_CUSTOMER_DETAILS,
  SELLER,
  SELLERSMALL,
  SpreetailRole,
  SpreetailRoleRestrictMessage,
  VENDOR,
  VENDORSMALL,
} from '../../../constants';

export default function AccountDetails({
  id,
  customerData,
  setShowModal,
  role,
  setStatusModal,
  showModal,
  IsSaveDataClicked,
  customStyles,
  isAuthorized,
  childBPList,
  history,
  getChildBpList,
  customerType,
}) {
  const userInfo = useSelector((state) => state.userState.userInfo);
  const isSpreetailUser = userInfo?.role === SpreetailRole;
  const [isCustomerStatusVisible, setIsCustomerStatusVisible] = useState(true);

  // authorized users list to move the BP's NEXT account to IN COLLECTIONS
  const haveInCollectionsAccess = userInfo?.can_change_status_to_collection;

  let statusActions = [
    { value: 'active', label: 'Activate' },
    { value: 'at risk', label: 'Place at risk' },
    {
      value: 'in collections',
      label: 'In Collections',
      isDisabled: !haveInCollectionsAccess,
    },
  ];

  const { Option } = components;

  const sellerCentral = [];
  const sellerCentralMarketplaces = [];
  const vendorCentral = [];
  const vendorCentralMarketplaces = [];

  const [customer, setCustomer] = useState(customerData);

  const customerDetails = useCallback(() => {
    getCustomerDetails(id).then((res) => {
      if (res?.status === 200) {
        setCustomer(res.data);
      }
    });
  }, [id]);

  useEffect(() => {
    if (role === 'Customer') customerDetails();
  }, [customerDetails, role]);

  useEffect(() => {
    customerDetails();
  }, [customerDetails, history]);

  const checkStatus = () => {
    if (customer?.status) {
      statusActions = statusActions.filter(
        (element) => element.value !== customer.status.value,
      );

      // if (customer?.status?.value === 'at risk') {
      //   statusActions.push(
      //     { value: 'active', label: 'Activate' },
      //     { value: 'inactive', label: 'Inactive' },
      //   );
      // }
    }
  };

  const checkStatusColor = (status) => {
    if (status) {
      if (status === 'inactive') {
        return Theme.gray60;
      }
      if (status === 'pending cancellation') {
        return Theme.yellow;
      }
      if (status === 'at risk') {
        return Theme.darkRed;
      }
      if (status === 'pending account setup') {
        return Theme.gray60;
      }
      if (status === 'out of contract') {
        return Theme.orange;
      }
      if (status === 'in collections') {
        return Theme.lightDarkBlue;
      }

      return Theme.darkGreen;
    }
    return '';
  };

  const displayMarketplaces = (
    centralMarketplacesArray,
    childBrands = false,
  ) => {
    return `<ul  style="padding: 0px 15px; margin: 9px 4px">
          ${centralMarketplacesArray?.map(
            (item) => `<li style="    padding:  0;
            background: transparent;
            border: none;
            color: white;
            text-align: left;
            display: list-item;">${!childBrands ? item : item?.label}</li>`,
          )}
          </ul>`.replaceAll(',', '');
  };

  const getAddressHTML = () => {
    const address = [
      customer?.address,
      customer?.city,
      customer?.state?.label || customer?.state,
      customer.zip_code,
      customer?.country?.label || customer?.country,
    ];
    return address?.filter((op) => op).join(', ');
  };

  const showSPAuthentication = (
    type,
    shortForm,
    centralArray,
    centralMarketplacesArray,
    authorizedImg,
    unauthorizedImg,
  ) => {
    for (const [key, value] of Object.entries(isAuthorized)) {
      if (typeof value === 'boolean') {
        centralArray.push(value);
        if (!value) centralMarketplacesArray.push(key);
      }
      if (typeof value === 'object') {
        centralArray.push(value[type]);
        if (!value[type] && type in value) centralMarketplacesArray.push(key);
      }
    }

    if (centralArray?.includes(false)) {
      return (
        <span className="cursor">
          <img
            src={unauthorizedImg}
            alt="UnauthorizedSC"
            data-tip={`Unauthorized marketplaces of ${shortForm}: ${displayMarketplaces(
              centralMarketplacesArray,
            )}`}
            data-for="unauthorized"
            style={{ marginRight: '4px' }}
          />
          <ReactTooltip
            id="unauthorized"
            aria-haspopup="true"
            place="bottom"
            html
            className="text-left"
          />
        </span>
      );
    }
    return (
      <span className="cursor mr-1">
        <img
          src={authorizedImg}
          alt="AuthorizedSC"
          data-tip={`BP has successfully authorized ${shortForm} data access.`}
          data-for="authorized"
          style={{ marginRight: '4px' }}
        />
        <ReactTooltip id="authorized" aria-haspopup="true" place="bottom" />
      </span>
    );
  };

  const IconOption = (dataProps) => {
    return (
      <Option {...dataProps}>
        <ReactTooltip
          id="companyname"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
        />
        <div
          className="row cursor"
          data-tip={dataProps.data.label}
          data-for="companyname"
        >
          <div className="col-5">
            <div className=" option-label">{dataProps.data.label}</div>
            <span className="extra-link-option">
              <FontAwesomeIcon
                icon="fa-light fa-arrow-up-right-from-square"
                style={{
                  marginLeft: '4px',
                  fontSize: '12px',
                  cursor: 'pointer',
                  color: '#FF5933 ',
                }}
              />
            </span>
            <div className="option-sub-title">
              {' '}
              {dataProps?.data?.bgs?.first_name}{' '}
              {dataProps?.data?.bgs?.last_name}
            </div>{' '}
          </div>
          <div className="col-7 capitalize  text-right">
            <Status
              className="capluse-status"
              label={dataProps?.data?.status}
              backgroundColor={checkStatusColor(dataProps?.data?.status)}
              labelColor={Theme.white}
            />
          </div>
        </div>
      </Option>
    );
  };

  const statusChangeOption = (dataProps) => {
    return (
      <Option {...dataProps}>
        <div>
          <span
            style={{ fontSize: '14px' }}
            data-tip="Only authorized user is permitted to change status."
            data-for={
              dataProps?.data?.value === 'in collections' &&
              dataProps?.isDisabled
                ? 'inCollectionAccessInfo'
                : ''
            }
          >
            {dataProps.data.label}
          </span>
          <ReactTooltip
            class="initialTextTransform"
            id="inCollectionAccessInfo"
            aria-haspopup="true"
            place="right"
            effect="solid"
          />
          <div style={{ fontSize: '10px' }}>{dataProps.data.sub}</div>
        </div>
      </Option>
    );
  };

  // the top panel links- addres, website, edit panel
  const renderLinksSections = () => {
    return (
      <>
        <ul className="inline-content icons-row">
          {getAddressHTML() ? (
            <li
              data-tip={getAddressHTML()}
              data-for="address"
              style={{ cursor: 'default' }}
            >
              <FontAwesomeIcon
                icon="fa-light fa-map-location-dot"
                className="icons-option"
              />
              <ReactTooltip
                id="address"
                aria-haspopup="true"
                place="bottom"
                effect="solid"
              />
            </li>
          ) : null}
          {customer?.website ? (
            <li
              data-tip={customer?.website}
              data-for="website"
              className="cursor"
            >
              {' '}
              <a
                style={{ textTransform: 'initial' }}
                href={
                  customer?.website?.includes('http')
                    ? customer?.website
                    : `http://www.${customer && customer.website}`
                }
                target="_blank"
                rel=" noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon="fa-light fa-link"
                  className="icons-option"
                />
                <ReactTooltip
                  id="website"
                  aria-haspopup="true"
                  place="bottom"
                  effect="solid"
                  textTransform="lowercase"
                />
              </a>
            </li>
          ) : null}
          <li
            data-tip={SpreetailRoleRestrictMessage}
            data-for={
              isSpreetailUser ? 'spreetailRetrictEditCompanyDetails' : ''
            }
            className={`cursor ${
              isSpreetailUser ? 'disabled-slider-btn' : ''
            } `}
            onClick={() => (!isSpreetailUser ? setShowModal(true) : null)}
            role="presentation"
          >
            <FontAwesomeIcon
              icon="fa-regular fa-pen"
              className="icons-option"
            />
          </li>
        </ul>
        <ReactTooltip
          id="spreetailRetrictEditCompanyDetails"
          aria-haspopup="true"
          place="bottom"
          effect="solid"
          html
        />
      </>
    );
  };

  const renderChildBpList = () => {
    return (
      <DropDownSelect
        className="child-bp-list"
        style={{ maxWidth: '320px', margin: '0 auto' }}
      >
        <Select
          options={childBPList.filter(
            (op) => !op?.label?.toLowerCase()?.includes('parent company'),
          )}
          placeholder="Type to Search Child Brand"
          styles={{
            control: (base) => ({
              ...base,
              borderRadius: '50px',
              minHeight: '24px !important',
              outline: 'none !important',
              boxShadow: 'none  !important',
              outLine: 'none',
              cursor: 'pointer',
              color: 'black',
              fontSize: '12px',
            }),
          }}
          onChange={(event) => {
            window.open(
              `${PATH_CUSTOMER_DETAILS.replace(
                ':id',
                event?.value,
              )}?tab=performance${
                event?.account_type
                  ? `&sub=${
                      event?.account_type === HYBRID ||
                      event?.account_type === SELLER
                        ? SELLERSMALL
                        : VENDORSMALL
                    }`
                  : ''
              }&nav=salePerformance`,
              '_blank',
            );
            getChildBpList();
          }}
          value=""
          components={{
            Option: IconOption,
            DropdownIndicator,
          }}
          componentsValue={{ Option: IconOption }}
        />
      </DropDownSelect>
    );
  };

  const renderCustomerStatus = () => {
    const customerStatus = customer?.status?.value;
    const isPending =
      customerStatus === 'pending account setup' ||
      customerStatus === 'pending';
    const isInactive = customerStatus === 'inactive';
    return (
      <>
        {customer?.status?.value !== null ? (
          role === 'Customer' ? (
            ''
          ) : isPending || isInactive ? (
            <>
              {isCustomerStatusVisible ? (
                <span
                  className={`company-status ${
                    isPending ? 'pending' : 'inactive'
                  }`}
                >
                  {customer?.status?.label}
                </span>
              ) : null}
              <FontAwesomeIcon
                style={{ color: '#000000', cursor: 'pointer' }}
                icon={`fal ${
                  isCustomerStatusVisible ? 'fa-eye-slash' : 'fa-eye'
                }`}
                className={`ml-2 `}
                onClick={() =>
                  setIsCustomerStatusVisible(!isCustomerStatusVisible)
                }
              />
            </>
          ) : isSpreetailUser ? (
            <>
              {isCustomerStatusVisible ? (
                <span
                  className="company-status"
                  style={{
                    background: checkStatusColor(customer?.status?.value),
                  }}
                >
                  {customer?.status?.label}
                </span>
              ) : null}
              <FontAwesomeIcon
                style={{ color: '#000000', cursor: 'pointer' }}
                icon={`fal ${
                  isCustomerStatusVisible ? 'fa-eye-slash' : 'fa-eye'
                }`}
                className={`ml-2 `}
                onClick={() =>
                  setIsCustomerStatusVisible(!isCustomerStatusVisible)
                }
              />
            </>
          ) : (
            <>
              {isCustomerStatusVisible ? (
                <DropDownStatus
                  className=" customer-details"
                  style={{ verticalAlign: 'unset', float: 'none' }}
                >
                  {checkStatus()}
                  <Select
                    isSearchable={false}
                    styles={{
                      control: (base) => ({
                        ...base,
                        background: checkStatusColor(customer?.status?.value),
                        borderRadius: '50px',
                        minHeight: '24px !important',
                        outline: 'none !important',
                        boxShadow: 'none  !important',
                        outLine: 'none',
                        cursor: 'pointer',
                        width:
                          customerStatus === 'pending cancellation' ||
                          customerStatus === 'pending account setup' ||
                          customerStatus === 'in collections' ||
                          customerStatus === 'out of contract'
                            ? '176px !important'
                            : customerStatus === 'at risk'
                            ? '120px'
                            : '88px',
                        '&:focus': {
                          outline: 'none !important',
                          boxShadow: 'none  !important',
                        },
                        '&:hover': {
                          outline: 'none',
                        },
                      }),
                      menu: (provided) => ({
                        ...provided,
                        color: '#000',
                      }),
                      singleValue: (provided) => {
                        const color =
                          customerStatus === 'pending cancellation'
                            ? Theme.black
                            : Theme.white;

                        return { ...provided, color };
                      },
                    }}
                    classNamePrefix="react-select"
                    options={statusActions}
                    onChange={(e) =>
                      setStatusModal({
                        show: true,
                        type: e.value,
                      })
                    }
                    isOptionDisabled={(option) => option?.isDisabled}
                    value={customer?.status}
                    components={{
                      Option: statusChangeOption,
                      DropdownIndicator: ({ ...props }) => (
                        <DropdownIndicator
                          indicatorColor={
                            customerStatus === 'pending cancellation'
                              ? '#000'
                              : '#fff'
                          }
                          {...props}
                        />
                      ),
                    }}
                  />
                </DropDownStatus>
              ) : null}

              <FontAwesomeIcon
                style={{ color: '#000000', cursor: 'pointer' }}
                icon={`fal ${
                  isCustomerStatusVisible ? 'fa-eye-slash' : 'fa-eye'
                }`}
                className={`ml-2 ${!isCustomerStatusVisible ? 'mb-3' : ''}`}
                onClick={() =>
                  setIsCustomerStatusVisible(!isCustomerStatusVisible)
                }
              />
            </>
          )
        ) : (
          <div className="company-status inactive capitalize mb-3 ">
            {customer?.contract_status}
          </div>
        )}
      </>
    );
  };

  // display company logo
  const displayCompanyLogo = (imageLink) => {
    return (
      <img
        className="brand-logo-image"
        src={imageLink || CompanyDefaultUser}
        alt="company-logo"
      />
    );
  };

  // function - display customer account type, SC/VC tag
  const displayBrandPartnerStatus = () => {
    return (
      <ul className="brand-status inline-content">
        {childBPList?.length ? (
          <>
            <li>
              <FontAwesomeIcon
                icon="fa-light fa-code-commit"
                className="brand-name-icon"
                fontSize="10px"
              />{' '}
              Parent Brand
            </li>
            <li
              className="child-count"
              data-tip={displayMarketplaces([...childBPList], true)}
              data-for={`${id}-child`}
            >
              <FontAwesomeIcon
                icon="fa-light fa-code-merge"
                className="brand-name-icon"
                fontSize="10px"
              />
              {childBPList?.length}
            </li>
            <ReactTooltip
              ReactTooltip
              id={`${id}-child`}
              key={`${id}-child`}
              class="initialTextTransform"
              aria-haspopup="true"
              place="bottom"
              effect="solid"
              html
            />
          </>
        ) : (
          <>
            {customer?.parent_name && customer?.child_bp_of ? (
              <li
                className="cursor"
                onClick={
                  role !== 'Customer'
                    ? () => {
                        history.push(
                          `${PATH_CUSTOMER_DETAILS.replace(
                            ':id',
                            customer?.child_bp_of,
                          )}?tab=performance&sub=seller&nav=salePerformance&access=internal
                        `,
                        );
                        getChildBpList(customer?.child_bp_of);
                      }
                    : ''
                }
                role="presentation"
              >
                <FontAwesomeIcon
                  icon="fa-light  fa-code-merge"
                  className="brand-name-icon"
                  fontSize="10px"
                />{' '}
                {customer.parent_name}
              </li>
            ) : (
              ''
            )}
            {customer?.customer_account_type ? (
              <li>{customer.customer_account_type}</li>
            ) : (
              ''
            )}
          </>
        )}

        {isAuthorized && Object.keys(isAuthorized)?.length ? (
          <li className="sp-auth">
            {customer?.customer_account_type === SELLER ||
            customer?.customer_account_type === HYBRID ? (
              <>
                {showSPAuthentication(
                  SELLERSMALL,
                  'SC',
                  sellerCentral,
                  sellerCentralMarketplaces,
                  AuthorizedSC,
                  UnauthorizedSC,
                )}
              </>
            ) : (
              ''
            )}
            {customer?.customer_account_type === VENDOR ||
            customer?.customer_account_type === HYBRID ? (
              <>
                {showSPAuthentication(
                  VENDORSMALL,
                  'VC',
                  vendorCentral,
                  vendorCentralMarketplaces,
                  AuthorizedVC,
                  UnauthorizedVC,
                )}
              </>
            ) : (
              ''
            )}
          </li>
        ) : (
          ''
        )}
      </ul>
    );
  };

  // display company profile details
  const displayCompanyProfile = () => {
    return (
      <div className="brand-logo-details">
        {displayCompanyLogo(
          customer?.documents?.[0]
            ? Object.values(customer.documents[0])
            : CompanyDefaultUser,
        )}
        <div className="brand-profile-content pl-md-2">
          {displayBrandPartnerStatus()}
          <div className="brand-name"> {customer?.company_name}</div>
          {childBPList?.length && customerType === PARENT
            ? renderChildBpList()
            : renderCustomerStatus()}
        </div>
      </div>
    );
  };

  // display BP account details
  const renderCustomerAccountDetails = () => {
    return (
      <BrandHeaderOption>
        {/* the top panel links- addres, website, edit panel */}
        {renderLinksSections()}

        <div
          className="edit-details edit-brand-details cursor d-none"
          onClick={() => setShowModal(true)}
          role="presentation"
        >
          <FontAwesomeIcon
            icon="fa-regular fa-pen"
            color="#FF5933"
            style={{ marginRight: '6px' }}
          />
          Edit
        </div>
        {displayCompanyProfile()}
      </BrandHeaderOption>
    );
  };
  // display Edit account details modal
  const displayEditAccountModal = () => {
    return (
      <Modal
        isOpen={showModal}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => setShowModal(false)}
          role="presentation"
        />
        <ModalBox>
          <EditAccountDetails
            setShowModal={setShowModal}
            setDocumentImage={customer.documents}
            IsSaveDataClicked={IsSaveDataClicked}
            id={id}
            customer={customer}
            setCustomer={setCustomer}
          />
        </ModalBox>
      </Modal>
    );
  };

  return (
    <>
      {renderCustomerAccountDetails()}
      {displayEditAccountModal()}
    </>
  );
}

AccountDetails.defaultProps = {
  id: '',
  customerType: 'standalone',
  history: {},
  customStyles: {},
  isAuthorized: {},
  childBPList: [],
  setShowModal: () => {},
  setStatusModal: () => {},
  getChildBpList: () => {},
  IsSaveDataClicked: () => {},
};

AccountDetails.propTypes = {
  id: string,
  customerType: string,
  role: string.isRequired,
  customerData: shape({
    id: string,
  }).isRequired,
  showModal: bool.isRequired,
  history: shape({}),
  customStyles: shape({}),
  isAuthorized: shape({}),
  childBPList: arrayOf(shape({})),
  setShowModal: func,
  setStatusModal: func,
  getChildBpList: func,
  IsSaveDataClicked: func,
};
