// React import
import React, { useState, useEffect, useCallback } from 'react';

// Third party imports
import queryString from 'query-string';
import { Collapse } from 'react-collapse';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Local imports
import AMCSelfServiceTable from './AMCSelfServiceTable';
import AMCSelfService from './AMCSelfServiceInstanceStyle';
import { ViewData, PageLoader } from '../../../../../common';
import { getAMCInstances, getAMCAdvertisers } from '../../../../../api';
import {
  requestTypeData,
  defaultCreateInstanceData,
  defaultUpdateInstanceData,
  defaultDeleteInstanceData,
  defaultCreateAdvertiserData,
  defaultDeleteAdvertiserData,
} from './AMCSelfServiceConstants';

function AMCSelfServiceInstanceContainer() {
  const [amcInstanceData, setAmcInstanceData] = useState(requestTypeData);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [loader, setLoader] = useState({ type: 'table', show: false });
  const [amcInstanceCount, setAmcInstanceCount] = useState({
    type: '',
    count: 0,
  });
  const [pageNumber, setPageNumber] = useState(1);
  const [collapseData, setCollapseData] = useState({
    amcInstance: requestTypeData[0],
    isOpen: 'false',
  });
  const history = useHistory();

  const params = queryString.parse(history.location.search);

  // find out specific data as per request type param from state variable.
  const getSpecificAMCInstanceByRequestType = (requestKey) => {
    return amcInstanceData.find((item) => item?.key === requestKey);
  };

  // function to update table data in the state variable amcInstancaData
  // update table data for specific request type provided in params
  const updateTableData = useCallback((requestKey, tableData) => {
    const data = requestTypeData?.map((item) => {
      if (item?.key === requestKey) {
        item.tableData = tableData;
        return item;
      }
      return item;
    });
    setAmcInstanceData(data);
  }, []);

  const getAMCInstanceData = useCallback(
    (requestType) => {
      setLoader({ type: 'table', show: true });
      let api = getAMCInstances;

      if (
        ['add-advertiser', 'delete-advertiser'].includes(
          collapseData?.amcInstance?.key,
        )
      ) {
        api = getAMCAdvertisers;
      }
      api(requestType, pageNumber).then((res) => {
        if (res?.status === 200) {
          const tableData = [...res?.data?.results];
          setAmcInstanceCount({
            type: collapseData?.amcInstance?.key,
            count: res?.data?.count,
          });
          updateTableData(collapseData?.amcInstance?.key, tableData);
        }
        setLoader({ type: 'table', show: false });
      });
    },
    [collapseData, updateTableData, pageNumber],
  );

  const checkAllMandatoryFieldsFilled = (amcInstances) => {
    const newlyAddedData = amcInstances.tableData?.filter((item) => !item.id);
    const result = [];
    if (newlyAddedData?.length) {
      newlyAddedData.forEach((instance) => {
        if (Object.keys(instance)?.length) {
          const copyObjOfNew = { ...instance };
          delete copyObjOfNew.status;
          delete copyObjOfNew.created_at;
          delete copyObjOfNew?.type;
          // this is extra condition of addition and deletion of advertiser tables
          if (
            ['add-advertiser', 'delete-advertiser'].includes(amcInstances?.key)
          ) {
            if (copyObjOfNew?.entity_id) {
              delete copyObjOfNew.advertiser_id;
            }
            if (copyObjOfNew?.advertiser_id) {
              delete copyObjOfNew.entity_id;
            }
          }
          result.push(Object.values(copyObjOfNew).every((value) => value));
        }
      });
      return Object.values(result).every((value) => value);
    }
    return false;
  };

  useEffect(() => {
    getAMCInstanceData(collapseData?.amcInstance?.requestType, pageNumber);
  }, [collapseData, getAMCInstanceData, pageNumber]);

  // this use effect will run after every 5 seconds to get new updated amc audience data
  useEffect(() => {
    let intervalId = '';
    let restrictMultipleApiCalls = null;

    if (!restrictMultipleApiCalls) {
      intervalId = setInterval(async () => {
        if (
          params?.subNav === 'amc-instance' &&
          !restrictMultipleApiCalls &&
          !isEditModeOn
        ) {
          getAMCInstanceData(
            collapseData?.amcInstance?.requestType,
            pageNumber,
          );

          restrictMultipleApiCalls = '1234';
        }
      }, 300000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [collapseData, getAMCInstanceData, pageNumber, params, isEditModeOn]);

  // get default dictionary when we click on add new button to push in table data
  // to show empty row at the end of table
  const getDefaultData = (requestType) => {
    const defaultData = {
      'create-instance': defaultCreateInstanceData,
      'update-instance': defaultUpdateInstanceData,
      'delete-instance': defaultDeleteInstanceData,
      'add-advertiser': defaultCreateAdvertiserData,
      'delete-advertiser': defaultDeleteAdvertiserData,
    };
    return defaultData?.[requestType];
  };

  // Add New row functionality
  const addNewRow = (requestKey) => {
    setIsEditModeOn(true);
    const defaultData = getDefaultData(requestKey);
    const requestTypeInfo = getSpecificAMCInstanceByRequestType(requestKey);
    const tableData = [...requestTypeInfo?.tableData];
    tableData.push({
      ...defaultData,
    });

    updateTableData(requestKey, tableData);
  };

  // Delete any row from table
  const deleteInstance = (tableDataIndex, amcInstance) => {
    setIsEditModeOn(false);

    const data = [];
    const { tableData } = amcInstance;
    tableData.forEach((instanceData, index) => {
      if (index !== tableDataIndex) {
        data.push(instanceData);
      }
    });
    updateTableData(amcInstance?.key, data);
  };

  // handle input box changes
  const handleInputChange = (event, amcInstance, tableDataIndex) => {
    const data = [];
    const { tableData } = amcInstance;

    tableData.forEach((instanceData, index) => {
      if (index === tableDataIndex) {
        const instance = {
          ...instanceData,
          [event?.target?.name]: event?.target?.value,
        };
        if (
          ['add-advertiser', 'delete-advertiser'].includes(amcInstance?.key)
        ) {
          if (instance?.entity_id) {
            instance.advertiser_type = 'sponsored_ads';
          }
          if (instance?.advertiser_id) {
            instance.advertiser_type = 'display';
          }
          if (!instance?.entity_id && !instance?.advertiser_id) {
            instance.advertiser_type = '';
          }
        }

        data.push(instance);
      } else {
        data.push(instanceData);
      }
    });
    updateTableData(amcInstance?.key, data);
  };

  // Main return
  return (
    <div>
      <ViewData>
        <div className="row">
          <div className="col-6 col-lg-3 mb-2 pr-0">
            <div className="black-heading-title d-inline-block mt-md-2">
              Self-Management Instance
            </div>
          </div>
        </div>
        {amcInstanceData?.map((amcInstance) => {
          return (
            <AMCSelfService
              className="amc-insight cursor "
              key={amcInstance?.key}
            >
              <div
                className="bg-orange-light"
                role="presentation"
                onClick={() => {
                  setCollapseData({
                    amcInstance,
                    isOpen: true,
                  });
                  setPageNumber(1);
                }}
              >
                <FontAwesomeIcon
                  color="#FF5933"
                  fontSize="12px"
                  icon={`${
                    collapseData?.isOpen &&
                    collapseData?.amcInstance?.key === amcInstance?.key
                      ? 'fa-regular fa-minus'
                      : 'fa-regular fa-plus'
                  }`}
                />
                {'  '}
                {amcInstance?.label}{' '}
                {amcInstanceCount?.type === amcInstance?.key &&
                amcInstanceCount?.count
                  ? `| ${amcInstanceCount?.count}`
                  : null}
              </div>
              <Collapse
                isOpened={
                  collapseData?.isOpen &&
                  collapseData?.amcInstance?.key === amcInstance?.key
                }
              >
                {loader?.show && loader?.type === 'table' ? (
                  <PageLoader
                    component="performance-graph"
                    type="detail"
                    width={40}
                    height={40}
                    color="#FF5933"
                  />
                ) : (
                  <AMCSelfServiceTable
                    amcInstances={amcInstance}
                    addNewRow={addNewRow}
                    handleInputChange={handleInputChange}
                    deleteInstance={deleteInstance}
                    getAMCInstanceData={getAMCInstanceData}
                    amcInstanceCount={amcInstanceCount}
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    loader={loader}
                    setLoader={setLoader}
                    checkAllMandatoryFieldsFilled={
                      checkAllMandatoryFieldsFilled
                    }
                    setIsEditModeOn={setIsEditModeOn}
                  />
                )}
              </Collapse>
            </AMCSelfService>
          );
        })}
      </ViewData>
    </div>
  );
}

export default AMCSelfServiceInstanceContainer;
