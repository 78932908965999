import axiosInstance from '../axios';

import { API_AMC_INSTANCE, API_AMC_ADVERTISER } from '../constants';

// get amc instances
export async function getAMCInstances(requestType, page) {
  const params = { request_type: requestType, page };

  const result = await axiosInstance
    .get(`${API_AMC_INSTANCE}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

// get amc advertisers
export async function getAMCAdvertisers(requestType, page) {
  const params = { request_type: requestType, page };

  const result = await axiosInstance
    .get(`${API_AMC_ADVERTISER}`, { params })
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function saveAMCInstance(data) {
  const result = await axiosInstance
    .post(`${API_AMC_INSTANCE}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}

export async function saveAMCAdvertiser(data) {
  const result = await axiosInstance
    .post(`${API_AMC_ADVERTISER}`, data)
    .then((response) => {
      return response;
    })
    .catch((error) => {
      return error.response;
    });
  return result;
}
