import React, { useState } from 'react';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { arrayOf, shape, bool, func } from 'prop-types';

import Theme from '../../../../../../theme/Theme';
import { NoRecordFound } from '../../../../../../common';

function DSPAudienceMobileView({
  dspAudienceData,
  isEditFlagOn,
  displayInputFields,
  DSPAudienceHeaders,
  displayValue,
  newDSPAudienceData,
}) {
  const [viewMoreDetailsFlag, setViewMoreDetailsFlag] = useState({
    index: null,
    flag: null,
  });
  const [viewMoreDetailsForNewRow, setViewMoreDetailsForNewRow] = useState({
    flag: null,
  });
  const displayTableData = (dspAudienceItem, dspAudienceItemIndex) => {
    if (
      viewMoreDetailsFlag?.index === dspAudienceItemIndex &&
      viewMoreDetailsFlag?.flag
    ) {
      return DSPAudienceHeaders?.map((audienceHeader) => {
        return (
          <div className="col-6 mb-3" key={audienceHeader?.label}>
            <div className="label mb-0" id={audienceHeader?.key}>
              {audienceHeader?.label}
            </div>
            <div className="normal-text-black text-medium">
              {displayValue(dspAudienceItem, audienceHeader)}
            </div>
          </div>
        );
      });
    }
    return DSPAudienceHeaders.slice(0, 4)?.map((audienceHeader) => {
      return (
        <div className="col-6 mb-3" key={audienceHeader?.label}>
          <div className="label mb-0" id={audienceHeader?.key}>
            {audienceHeader?.label}
          </div>
          <div className="normal-text-black text-medium">
            {displayValue(dspAudienceItem, audienceHeader)}
          </div>
        </div>
      );
    });
  };

  const displayNewlyAddedRow = () => {
    if (viewMoreDetailsForNewRow?.flag) {
      return DSPAudienceHeaders?.map((audienceHeader, headerIndex) => {
        return (
          <div className="col-6 mb-3" key={audienceHeader?.label}>
            <div className="label mb-0" id={audienceHeader?.key}>
              {audienceHeader?.label}
            </div>
            <div className="normal-text-black text-medium">
              {displayInputFields(audienceHeader, headerIndex)}
            </div>
          </div>
        );
      });
    }
    return DSPAudienceHeaders.slice(0, 4)?.map(
      (audienceHeader, headerIndex) => {
        return (
          <div className="col-6 mb-3" key={audienceHeader?.label}>
            <div className="label mb-0" id={audienceHeader?.key}>
              {audienceHeader?.label}
            </div>
            <div className="normal-text-black text-medium">
              {displayInputFields(audienceHeader, headerIndex)}
            </div>
          </div>
        );
      },
    );
  };

  const displayTable = () => {
    return (
      <>
        {dspAudienceData?.length ? (
          dspAudienceData?.map((dspAudienceItem, dspAudienceItemIndex) => {
            return (
              <fieldset className="shape-without-border w-430 mt-2 mb-4">
                <div className="row" id={dspAudienceItem.id}>
                  {displayTableData(dspAudienceItem, dspAudienceItemIndex)}

                  <div
                    className="capsule-collpase"
                    role="presentation"
                    onClick={() => {
                      setViewMoreDetailsFlag({
                        index: dspAudienceItemIndex,
                        flag: !viewMoreDetailsFlag.flag,
                      });
                    }}
                  >
                    {viewMoreDetailsFlag?.index === dspAudienceItemIndex &&
                    viewMoreDetailsFlag?.flag
                      ? 'View less details'
                      : `View 7 more details`}
                    <FontAwesomeIcon
                      icon="fa-regular fa-angle-down"
                      className="dropdown-arrow-icon"
                      style={{
                        transform:
                          viewMoreDetailsFlag?.index === dspAudienceItemIndex &&
                          viewMoreDetailsFlag?.flag
                            ? 'rotate(180deg)'
                            : '',
                        marginLeft: '10px',
                        verticalAlign: 'middle',
                      }}
                    />
                  </div>
                </div>
              </fieldset>
            );
          })
        ) : (
          <div className="row">
            <NoRecordFound
              type="DSP-audience"
              title="We looked high and low, but…"
              subTitle="No records found."
            />
          </div>
        )}
      </>
    );
  };

  const displayNewlyAddedDSPAudienceRow = () => {
    return isEditFlagOn && Object.keys(newDSPAudienceData)?.length ? (
      <fieldset className="shape-without-border w-430 mt-2 mb-4">
        <div className="row">
          {displayNewlyAddedRow(newDSPAudienceData)}
          <div
            className="capsule-collpase"
            role="presentation"
            onClick={() => {
              setViewMoreDetailsForNewRow({
                flag: !viewMoreDetailsForNewRow.flag,
              });
            }}
          >
            {viewMoreDetailsForNewRow?.flag
              ? 'View less details'
              : `View 7 more details`}
            <FontAwesomeIcon
              icon="fa-regular fa-angle-down"
              className="dropdown-arrow-icon"
              style={{
                transform: viewMoreDetailsForNewRow?.flag
                  ? 'rotate(180deg)'
                  : '',
                marginLeft: '10px',
                verticalAlign: 'middle',
              }}
            />
          </div>
        </div>
      </fieldset>
    ) : (
      ''
    );
  };

  return (
    <DSPAudienceMobileViewStyle>
      {displayTable()}
      {displayNewlyAddedDSPAudienceRow()}
    </DSPAudienceMobileViewStyle>
  );
}

export default DSPAudienceMobileView;

DSPAudienceMobileView.defaultProps = {
  dspAudienceData: [],
  isEditFlagOn: false,
  displayInputFields: () => {},
  DSPAudienceHeaders: [],
  displayValue: () => {},
  newDSPAudienceData: {},
};

DSPAudienceMobileView.propTypes = {
  dspAudienceData: arrayOf(shape({})),
  isEditFlagOn: bool,
  displayInputFields: func,
  DSPAudienceHeaders: arrayOf(shape({})),
  displayValue: func,
  newDSPAudienceData: shape({}),
};

const DSPAudienceMobileViewStyle = styled.div`
  .shape-without-border {
    position: relative;
  }
  .capsule-collpase {
    border-radius: 20px;
    bottom: 0;
    border: 1px solid ${Theme.gray11};
    color: ${Theme.black};
    font-size: ${Theme.extraNormal};
    font-family: Noah-Medium;
    background: ${Theme.white};
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 10px 3px 12px;
    text-align: center;
    position: absolute;
    margin: -18px auto;
    right: 0;
    left: 0;
  }
  .asin-trash {
    padding-right: 5px;
  }

  .display-flex {
    display: flex;
  }
`;
