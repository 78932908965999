import queryString from 'query-string';

export const pageTitle = [
  {
    type: 'url',
    url: `/signin`,
    title: 'Next | Sign In',
  },

  // Dashboard main links
  {
    type: 'url',
    url: `/dashboard/bbe-Goal/`,
    title: 'Next | Home',
  },
  {
    type: 'url',
    url: `/dashboard/sales`,
    title: 'Next | Sales Dashboard',
  },
  {
    type: 'url',
    url: `/dashboard/sponsored`,
    title: 'Next | Sponsored Advertising Dashboard',
  },
  {
    type: 'url',
    url: `/dashboard/dsp`,
    title: 'Next | DSP Advertising Dashboard',
  },
  {
    type: 'url',
    url: `/dashboard/inventory`,
    title: 'Next | Inventory Dashboard',
  },

  {
    type: 'url',
    url: `/dashboard/finance/retainer`,
    title: 'Next | Monthly Retainer',
  },
  {
    type: 'url',
    url: `/dashboard/finance/rev-share`,
    title: 'Next | Rev Share',
  },
  {
    type: 'url',
    url: `/dashboard/finance/up-sell`,
    title: 'Next | Upsell',
  },
  {
    type: 'url',
    url: `/dashboard/finance/dsp`,
    title: 'Next | DSP',
  },

  {
    type: 'url',
    url: `/dashboard/finance/amc`,
    title: 'Next | AMC',
  },

  {
    type: 'url',
    url: `/dashboard/finance/dsp-billing`,
    title: 'Next | DSP Billing',
  },

  {
    type: 'url',
    url: `/dashboard/amcDashboard`,
    title: 'Next | AMC Insights',
  },

  {
    type: 'url',
    url: `/dashboard/finance/pendingApprovals`,
    title: 'Next | Pending Approvals',
  },

  {
    type: 'url',
    url: `/dashboard/pendingApprovals`,
    title: 'Next | Pending Approvals',
  },

  {
    type: 'url',
    url: `/customer`,
    title: 'Next | Partners',
  },
  // KB page title- start
  {
    type: 'url',
    url: `/collections/`,
    title: 'Next | Knowledge Base',
  },

  {
    type: 'url',
    url: `/collections/aff1eb5e-7bbe-404e-9082-aabd7c492526/`,
    title: 'Next | KB-Account Management',
  },
  {
    type: 'url',
    url: `/collections/5e0c6c5e-122c-4172-a421-a5dc346d16bc/`,
    title: 'Next | KB-Advertising',
  },
  {
    type: 'url',
    url: `/collections/4a3e460e-de0f-45d0-ada4-3c0eb03e3968/`,
    title: 'Next | KB-Amazon 101',
  },

  {
    type: 'url',
    url: `/collections/28233f9a-3584-4c87-a72e-276794ddeb94/`,
    title: 'Next | KB-BBE',
  },
  {
    type: 'url',
    url: `/collections/c3421b19-e2a7-4feb-a371-026605cf2932/`,
    title: 'Next | KB-Catalog',
  },
  {
    type: 'url',
    url: `/collections/bd420217-adc1-4784-b85c-a7eceaaf4b0d/`,
    title: 'Next | KB-Company Knowledge',
  },
  {
    type: 'url',
    url: `/collections/61835830-0a80-4575-a662-6da4af86539d/`,
    title: 'Next | KB-Creative',
  },
  {
    type: 'url',
    url: `/collections/c5b50a22-cd55-4d1e-9105-2f1e945b7d02/`,
    title: 'Next | KB-Customer Service',
  },
  {
    type: 'url',
    url: `/collections/6c220883-3b29-49c6-bf41-96d6a7be8128/`,
    title: 'Next | KB-Executive Assistants',
  },
  {
    type: 'url',
    url: `/collections/619c5eb3-3fcf-480d-a96f-7f15d398ac9d/`,
    title: 'Next | KB-Getting Started with Guru',
  },
  {
    type: 'url',
    url: `/collections/e1c87e3e-624d-4df8-83e1-ba200f517e1f/`,
    title: 'Next | KB-Human Resources',
  },
  {
    type: 'url',
    url: `/collections/4538b00b-ab81-47ea-8e37-fb8632a7fb37/`,
    title: 'Next | KB-International Expansion Services',
  },
  {
    type: 'url',
    url: `/collections/fa4546eb-bc2c-45b0-90d3-f8322f90e8ca/`,
    title: 'Next | KB-Logistics',
  },
  {
    type: 'url',
    url: `/collections/402e3293-6045-4484-9cec-f17c5d908473/`,
    title: 'Next | KB-Marketing',
  },
  {
    type: 'url',
    url: `/collections/fc2dfb34-31ca-4cb9-8d5e-0c00deaacacd/`,
    title: 'Next | KB-NEXT',
  },
  {
    type: 'url',
    url: `/collections/708b6313-7f25-4fa5-aa21-e3e486f31628/`,
    title: 'Next | Productivity Tools',
  },
  {
    type: 'url',
    url: `/collections/8a8dd5fd-f957-409b-88b2-f18b217cedb8/`,
    title: 'Next | KB-Sales',
  },
  {
    type: 'url',
    url: `/collections/cab2dcbe-bed2-41a8-817f-cb5e8d21f011/`,
    title: 'Next | KB-Seller Support - Seller Performance',
  },
  {
    type: 'url',
    url: `/collections/e69b3020-6cb6-4ecd-bf39-732520a8d7fd/`,
    title: 'Next | KB-Technology',
  },
  {
    type: 'url',
    url: `/collections/1e63bbfb-9c12-4b0b-b714-bb76f2ecae3f/`,
    title: 'Next | KB-Vendor Collection',
  },

  // KB page title- END

  {
    type: 'url',
    url: `/reports/standard`,
    title: 'Next | Standard Reports',
  },
  {
    type: 'url',
    url: `/reports/customized`,
    title: 'Next | Customized Reports',
  },
  {
    type: 'url',
    url: `/reports/summary`,
    title: 'Next | Reporting Dashboard',
  },
  {
    type: 'url',
    url: `/agreement/`,
    title: 'Next | Agreements',
  },

  {
    type: 'url',
    url: `/company-details/`,
    title: 'Next | Company Details',
  },
  // Agreement Constants
  {
    type: 'nav',
    url: `recurring`,
    title: 'Next | Recurring Agreement',
  },
  {
    type: 'nav',
    url: `one-time`,
    title: 'Next | One Time Agreement',
  },
  //  Performance Constants
  {
    type: 'nav',
    url: `salePerformance`,
    title: 'Next | BP Sales Performance',
  },
  {
    type: 'nav',
    url: `adPerformance`,
    title: 'Next | BP Sponsored Ad Performance',
  },
  {
    type: 'nav',
    url: `dspPerformance`,
    title: 'Next | BP DSP Ad Performance',
  },
  {
    type: 'nav',
    url: `amcInsights`,
    title: 'Next | BP AMC Insights',
  },

  {
    type: 'nav',
    url: `creativeProjects`,
    title: 'Next | BP Creative Projects',
  },

  // Billing Constants
  {
    type: 'nav',
    url: `retainer`,
    title: 'Next | Monthly Retainer Billing',
  },
  {
    type: 'nav',
    url: `rev share`,
    title: 'Next | Rev Share Billing',
  },
  {
    type: 'nav',
    url: `upsell`,
    title: 'Next | Upsell Billing',
  },
  {
    type: 'nav',
    url: `dsp service`,
    title: 'Next | Dsp Service Billing',
  },

  {
    type: 'nav',
    url: `amc`,
    title: 'Next | AMC Service Billing',
  },
  {
    type: 'nav',
    url: `billing-details`,
    title: 'Next | Billing Details',
  },

  // inventoryManagement
  {
    type: 'tab',
    url: `inventoryManagement`,
    title: 'Next | Inventory Management',
  },
  // Company Details
  {
    type: 'tab',
    url: `company-details`,
    title: 'Next | Company Details',
  },
  // Activity
  {
    type: 'tab',
    url: `activity`,
    title: 'Next | Activity',
  },
  // Partner Feedback
  {
    type: 'tab',
    url: `feedback`,
    title: 'Next | Partner Feedback',
  },

  // PlayBook Constants
  {
    type: 'nav',
    url: `onboarding`,
    title: 'Next | Onboarding Playbook',
  },
  {
    type: 'nav',
    url: `playbook`,
    title: 'Next | Playbook',
  },
  {
    type: 'nav',
    url: `standard-monthly`,
    title: 'Next | Standard Monthly Playbook',
  },
  {
    type: 'nav',
    url: `additional-one-time`,
    title: 'Next | Additional One Time Playbook',
  },

  // Goals and Strategy
  {
    type: 'tab',
    url: `goals`,
    title: 'Next | Forecast',
  },
  {
    type: 'tab',
    url: `cos`,
    title: 'Next | COS',
  },

  // Customer Side
  {
    type: 'tab',
    url: `dashboard`,
    title: 'Next | BP Dashboard',
  },
  {
    type: 'tab',
    url: `reports`,
    title: 'Next | BP Reports',
  },
];

let found = false;

const returnPageTitle = (param, location) => {
  return pageTitle?.forEach((item) => {
    if (item.url === param) {
      document.title = item.title;
      found = true;
    }
    const params = queryString.parse(location.search);

    if (item.url === '/dashboard/amcDashboard') {
      if (params?.subNav === 'amc-summary') {
        document.title = 'Next | AMC Insights | BBE-AMC Summary';
      }
      if (params?.subNav === 'spreetail-amc-summary-tab') {
        document.title = 'Next | AMC Insights | Spreetail-AMC Summary';
      }
      if (params?.subNav === 'sample') {
        document.title = 'Next | AMC Insights | Sample Dashboard';
      }
      if (params?.subNav === 'amc-audience-summary') {
        document.title = 'Next | AMC Insights | Rule based Audience';
      }
      if (params?.subNav === 'amc-instance') {
        document.title = 'Next | AMC Insights | AMC Instance';
      }
      if (params?.amcAudience === 'lookalike') {
        document.title = 'Next | AMC Insight | Lookalike Audience';
      }
    }

    if (item.url === 'amcInsights') {
      if (params?.nav === 'amcInsights') {
        if (params?.subNav === 'standard') {
          document.title = 'Next | BP AMC Insight | Standard';
        }
        if (params?.subNav === 'advanced') {
          document.title = 'Next | BP AMC Insight | Advanced';
        }
        if (params?.subNav === 'customized') {
          document.title = 'Next | BP AMC Insight | Customized';
        }
        if (params?.subNav === 'sample') {
          document.title = 'Next | BP AMC Insight | Sample';
        }
        if (params?.subNav === 'dsp-audience') {
          document.title = 'Next | BP AMC Insight | Rule based Audience';
        }
        if (params?.amcAudience === 'lookalike') {
          document.title = 'Next | BP AMC Insight | Lookalike Audience';
        }
      }
      found = true;
    }

    if (param?.includes('customer') && param?.includes('agreement')) {
      found = true;
      document.title = 'Next | Agreement';
    }
    if (param?.includes('account-setup') && param?.includes('account-setup')) {
      found = true;
      document.title = 'Next | Account Setup';
    }
  });
};

// Return proper page title
export const pageTitleConditions = (location, data = null) => {
  found = false;
  const params = queryString.parse(location.search);

  if (Object.entries(params)?.length) {
    if (params.nav && params.nav !== 'undefined') {
      returnPageTitle(params.nav, location);
    } else {
      returnPageTitle(params.tab, location);
    }
  }
  if (params?.board || location?.pathname?.includes('collections')) {
    const colletionName = pageTitle.filter(
      (item) => item.url === location.pathname,
    );

    if (colletionName?.length && data?.title) {
      document.title = `${colletionName[0].title}-${data?.title}`;
      found = true;
    }
  } else {
    returnPageTitle(location.pathname, location);
  }
  if (!found) {
    document.title = 'Next';
  }
};
