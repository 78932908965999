import React, { useState } from 'react';

import queryString from 'query-string';
import { func } from 'prop-types';
import { useHistory } from 'react-router-dom';

import AMCDashboardLeftSidePanel from './AMCDashboardLeftSidePanel';
// import AMCDashboardRightSidePanel from './AMCDashboardRightSidePanel';
import AMCDashboardRightPanel from './AMCDashboardRightPanel';

const AMCAggregatedDashboardContainer = ({
  bindUserListData,
  handleMultiSelectDropdownFilterEvents,
}) => {
  const history = useHistory();
  const params = queryString.parse(history.location.search);

  const [selectedSecondLevelNav, setSelectedSecondLevelNav] = useState(
    params?.subNav || 'amc-summary',
  );

  const defaultMarketPlace = {
    value: 'all',
    label: 'All Marketplaces',
    currency: 'USD',
  };
  const [selectedMarketplace, setSelectedMarketplace] =
    useState(defaultMarketPlace);
  const [selectedDSPManager, setSelectedDSPManager] = useState([]);
  const [selectedBp, setSelectedBp] = useState([]);

  return (
    <div className="row mt-3">
      {selectedSecondLevelNav === 'amc-summary' ||
      selectedSecondLevelNav === 'spreetail-amc-summary-tab' ||
      selectedSecondLevelNav === 'amc-instance' ||
      selectedSecondLevelNav === 'sample' ? (
        ''
      ) : (
        <div className="col-xl-2 col-lg-3 col-md-12 col-12">
          <AMCDashboardLeftSidePanel
            selectedMarketplace={selectedMarketplace}
            setSelectedMarketplace={setSelectedMarketplace}
            bindUserListData={bindUserListData}
            handleMultiSelectDropdownFilterEvents={
              handleMultiSelectDropdownFilterEvents
            }
            selectedDSPManager={selectedDSPManager}
            setSelectedDSPManager={setSelectedDSPManager}
            selectedBp={selectedBp}
            setSelectedBp={setSelectedBp}
          />
        </div>
      )}
      <div
        className={`col-md-12 col-12 ${
          selectedSecondLevelNav === 'amc-audience-summary'
            ? 'col-xl-10 col-lg-9'
            : ''
        }`}
      >
        <AMCDashboardRightPanel
          selectedBp={selectedBp}
          selectedDSPManager={selectedDSPManager}
          selectedMarketplace={selectedMarketplace}
          selectedSecondLevelNav={selectedSecondLevelNav}
          setSelectedSecondLevelNav={setSelectedSecondLevelNav}
        />
      </div>
    </div>
  );
};

export default AMCAggregatedDashboardContainer;

AMCAggregatedDashboardContainer.defaultProps = {
  bindUserListData: () => {},
  handleMultiSelectDropdownFilterEvents: () => {},
};

AMCAggregatedDashboardContainer.propTypes = {
  bindUserListData: func,
  handleMultiSelectDropdownFilterEvents: func,
};
