import React, { useState, useEffect, useCallback } from 'react';

import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { arrayOf, shape, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';

import Theme from '../../../../theme/Theme';
import NoRecordFound from '../../../../common/NoRecordFound';
import CustomerFeedbackDetails from './CustomerFeedbackDetails';
import {
  emailRecipientFields,
  emailRecipientOption,
  customStylesForTableModal,
} from '../../../../constants';
import {
  EditOrangeIcon,
  CloseIcon,
  AddNewIcons,
  TrashIcons,
  DefaultUser,
  EmailIcon,
  SaveIcon,
} from '../../../../theme/images';
import { NotesSideBar } from '../CustomerDetailStyles';
import {
  getCustomerFeedback,
  getMonkeySurveyData,
  updateMonkeySurveyData,
  sendReminderOfCustomerFeedback,
  updateRecipientOfCustomerFeedback,
} from '../../../../api';
import {
  DesktopTable,
  PageLoader,
  Status,
  ViewData,
  WhiteCard,
  DropdownIndicator,
  SingleSelectDropdown,
  ModalBox,
  Button,
  FormField,
  ModalRadioCheck,
  GrayMessageBar,
  CommonPagination,
  // GetInitialName,
} from '../../../../common';

export default function CustomerFeedbackList({
  id,
  customerType,
  childBPList,
  memberData,
}) {
  const customStyles = {
    content: {
      top: '50%',
      right: '0px',
      bottom: 'auto',
      maxWidth: '600px ',
      width: '100% ',
      maxHeight: '100%',
      overlay: ' {zIndex: 1000}',
      inset: '0% 0% 0% auto',
      marginRight: '0',
      borderRadius: '0px !important',
    },
  };
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const isExtraLargeDesktop = useMediaQuery({ minWidth: 1800 });
  const userInfo = useSelector((state) => state?.userState?.userInfo);
  const [isLoading, setIsLoading] = useState({ loader: true, type: 'page' });
  const [showDetailedFeedback, setShowDetailedFeedback] = useState(false);
  const [selectedFeedbackForm, setSelectedFeedbackForm] = useState({});
  const [surveyData, setSurveyData] = useState([]);
  const [onboadingsurveyData, setOnboardingSurveyData] = useState([]);
  const [surveyCount, setSurveyCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [changeEmailRecipientData, setChangeEmailRecipientData] = useState({
    current_info: {},
    next_all_info: {},
  });

  const [uniqueRecipients, setUniqueRecipients] = useState([]);

  const [flagsForModal, setFlagsForModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [selectedCustomerId, setSelectedCustomerId] = useState(
    childBPList?.length ? childBPList?.[0]?.value : id,
  );
  const [isAllowToEditOrSkipSurvey, setIsAllowToEditOrSkipSurvey] =
    useState(false);

  // checking user permission
  const checkUserPermission = useCallback(() => {
    if (userInfo?.role?.includes('BGS Admin')) {
      setIsAllowToEditOrSkipSurvey(true);
    } else {
      for (const user of memberData) {
        if (user.user) {
          if (
            (user?.role_group?.name === 'BGS Manager' ||
              user?.role_group?.name === 'BGS') &&
            user?.user?.id === userInfo?.id
          ) {
            setIsAllowToEditOrSkipSurvey(true);
          }
        }
      }
    }
  }, [memberData, userInfo]);

  useEffect(() => {
    checkUserPermission();
  }, [checkUserPermission]);

  useEffect(() => {
    if (childBPList?.length) setSelectedCustomerId(childBPList?.[0]?.value);
  }, [childBPList]);

  const getOnboardingSurveyData = useCallback(() => {
    getCustomerFeedback(selectedCustomerId).then((res) => {
      if (res?.status === 200) {
        setOnboardingSurveyData(res?.data);
      }
      setIsLoading({ loader: false, type: 'page' });
    });
  }, [selectedCustomerId]);

  const getSurveyData = useCallback(() => {
    setSurveyData([]);
    getMonkeySurveyData(selectedCustomerId, pageNumber).then((survey) => {
      if (survey?.status === 200) {
        setSurveyCount(survey?.data?.count);
        setUniqueRecipients(survey?.data?.recipient_details);
        for (const item of survey?.data?.results) {
          if (item?.survey_responses?.length) {
            for (const qa of item.survey_responses) {
              qa.id = item.id;
              qa.sent_on = item.sent_on;
              qa.status = item.status;
              qa.survey_name = item.survey_name;
              qa.recipient_email = item.recipient_email;
              qa.recipient_first_name = item.recipient_first_name;
              qa.recipient_last_name = item.recipient_last_name;
            }
            setSurveyData((prevState) => [
              ...prevState,
              ...item.survey_responses,
            ]);
          } else {
            setSurveyData((prevState) => [...prevState, item]);
          }
        }
      }
      setIsLoading({ loader: false, type: 'page' });
    });
  }, [selectedCustomerId, pageNumber]);

  useEffect(() => {
    setIsLoading({ loader: true, type: 'page' });
    getOnboardingSurveyData();
  }, [getOnboardingSurveyData]);

  useEffect(() => {
    setIsLoading({ loader: true, type: 'page' });
    getSurveyData();
  }, [getSurveyData]);

  const skipSurvey = () => {
    setIsLoading({ loader: true, type: 'button' });
    updateMonkeySurveyData(modalConfig?.item?.id, { status: 'skipped' }).then(
      (res) => {
        setIsLoading({ loader: false, type: 'button' });

        if (res?.status === 200) {
          toast.success('Skipped survey Successfully.');
          setModalConfig({
            modalFor: 'skip',
            show: false,
          });
          getSurveyData();
        }
      },
    );
  };

  const updateRecipient = () => {
    setIsLoading({ loader: true, type: 'button' });
    updateRecipientOfCustomerFeedback(changeEmailRecipientData).then((res) => {
      setIsLoading({ loader: false, type: 'button' });

      if (res?.status === 200) {
        toast.success('Email Recipient updated Successfully.');
        setModalConfig({
          modalFor: 'sendReminder',
          show: false,
        });
        getSurveyData();
      }
    });
  };
  const sendReminderOfContract = () => {
    setIsLoading({ loader: true, type: 'button' });
    sendReminderOfCustomerFeedback({
      object_id: modalConfig?.item?.id,
    }).then((res) => {
      setIsLoading({ loader: false, type: 'button' });

      if (res?.status === 200) {
        toast.success('Reminder email has been sent successfully');
        setModalConfig({
          modalFor: 'sendReminder',
          show: false,
        });

        getSurveyData();
      }
    });
  };

  const renderSkipModal = () => {
    return (
      <Modal
        isOpen={modalConfig?.modalFor === 'skip' && modalConfig?.show}
        style={customStylesForTableModal}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setModalConfig({
              modalFor: 'skip',
              show: false,
            });
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body">
            {/* <div className="heading-title text-center mt-1">Skip survey</div> */}

            <div className="col-12 modal-heading text-center ">
              <h4>Skip survey</h4>
            </div>
            <div className="alert-msg pb-3 mt-3">
              Are you sure you would like to skip sending this survey?
              <div className="sure-to-proceed">
                This action can not be undone.
              </div>
            </div>
            <div className="row mt-1">
              <div className="col-6 mt-4">
                <Button
                  className="btn-primary w-100"
                  onClick={() => skipSurvey()}
                >
                  {isLoading?.loader && isLoading?.type === 'button' ? (
                    <PageLoader color="#fff" type="button" />
                  ) : (
                    'Proceed'
                  )}
                </Button>
              </div>
              <div className="col-6 mt-4">
                <Button
                  className="btn-transparent w-100"
                  onClick={() =>
                    setModalConfig({
                      modalFor: 'skip',
                      show: false,
                    })
                  }
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  const renderSendReminderModal = () => {
    return (
      <Modal
        isOpen={modalConfig?.modalFor === 'sendReminder' && modalConfig?.show}
        style={customStylesForTableModal}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setModalConfig({
              modalFor: 'sendReminder',
              show: false,
            });
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body on-boarding">
            <h4 className="on-boarding mb-4">Send Reminder</h4>

            <div className="row">
              <div className="col-12">
                <div className="signature-request">
                  TO:{' '}
                  <span className="email-address">
                    {modalConfig?.item?.recipient_email}
                  </span>
                </div>
                {/* <ErrorMsg>{reminderError?.Contact} </ErrorMsg> */}
              </div>
            </div>
            <div className=" mt-4">
              <Button
                className=" btn-primary on-boarding w-100"
                onClick={() => sendReminderOfContract()}
                //  disabled={disabledCon || false}>
              >
                Send Reminder
              </Button>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  const returnRecipientDetails = () => {
    const fields = [];
    emailRecipientFields.map((contact) => {
      return fields.push(
        <div className={contact?.classname}>
          <FormField className="mt-3">
            <input
              className="form-control"
              type={contact?.type}
              placeholder={contact?.placeholder}
              name={contact?.key}
              value={changeEmailRecipientData?.current_info?.[contact?.key]}
              onChange={(event) => {
                setChangeEmailRecipientData({
                  ...changeEmailRecipientData,
                  current_info: {
                    id: modalConfig?.item?.id,
                    ...changeEmailRecipientData?.current_info,
                    [event?.target?.name]: event?.target?.value,
                  },
                });
              }}
            />
          </FormField>
        </div>,
      );
    });
    return fields;
  };

  const displayAddNewContactForm = () => {
    const fields = [];
    fields.push(
      <div className="col-12" id="add-contact">
        <fieldset className="mb-3">
          <>
            {emailRegex.test(
              changeEmailRecipientData?.current_info?.recipient_email,
            ) ? (
              <img
                className="remove-contact cursor mr-4"
                src={SaveIcon}
                alt="save"
                role="presentation"
                onClick={() => {
                  if (
                    Object.keys(changeEmailRecipientData?.current_info)?.length
                  ) {
                    if (
                      Object.keys(
                        uniqueRecipients?.filter(
                          (recipient) =>
                            recipient?.recipient_email ===
                            changeEmailRecipientData?.current_info
                              ?.recipient_email,
                        ),
                      ).length === 0
                    ) {
                      uniqueRecipients.push(
                        changeEmailRecipientData?.current_info,
                      );
                      setUniqueRecipients(uniqueRecipients);
                    }
                  }

                  setFlagsForModal({
                    ...flagsForModal,
                    addNewContactFlag: false,
                  });
                }}
              />
            ) : null}
            <img
              className="remove-contact cursor"
              src={TrashIcons}
              alt="delete"
              role="presentation"
              onClick={() => {
                setFlagsForModal({
                  ...flagsForModal,
                  addNewContactFlag: false,
                });

                setChangeEmailRecipientData({
                  ...changeEmailRecipientData,
                  current_info: {},
                });
              }}
            />

            <div className="row">
              <div className="col-2 text-center">
                {' '}
                <img
                  src={DefaultUser}
                  alt="user"
                  className="contact-user  mt-4"
                />
              </div>
              <div className="col-10">
                <div className="row">{returnRecipientDetails()}</div>
              </div>
            </div>
          </>
        </fieldset>
      </div>,
    );
    return fields;
  };

  const handleEmailRecipientRadioBtn = (event) => {
    setFlagsForModal({
      ...flagsForModal,
      succeeding_email: event?.target?.value,
    });
    if (
      event?.target?.value === 'no' &&
      Object.keys(changeEmailRecipientData?.next_all_info)?.length === 0
    ) {
      setChangeEmailRecipientData({
        ...changeEmailRecipientData,
        next_all_info: {
          recipient_first_name: modalConfig?.item?.recipient_first_name,
          recipient_last_name: modalConfig?.item?.recipient_last_name,
          recipient_email: modalConfig?.item?.recipient_email,
        },
      });
    }
    if (event?.target?.value === 'yes') {
      setChangeEmailRecipientData({
        ...changeEmailRecipientData,
        next_all_info: {
          ...changeEmailRecipientData?.current_info,
        },
      });
    }
  };

  const displayContact = () => {
    return uniqueRecipients.map((info) => {
      return (
        <ModalRadioCheck className="gray-bg">
          <label
            className="radio-container"
            htmlFor={`email_recipient${info.recipient_email}`}
          >
            {info.recipient_first_name} {info && info.recipient_last_name}
            <br />
            <input
              type="radio"
              checked={
                changeEmailRecipientData?.current_info?.recipient_email ===
                info?.recipient_email
              }
              name="radio"
              id={`email_recipient${info.recipient_email}`}
              onClick={() => {
                const obj =
                  info?.recipient_email !==
                  changeEmailRecipientData?.next_all_info?.recipient_email
                    ? changeEmailRecipientData?.next_all_info
                    : {};
                setChangeEmailRecipientData({
                  ...changeEmailRecipientData,
                  current_info: { ...info, id: modalConfig?.item?.id },
                  next_all_info: { ...obj },
                });
              }}
            />
            <span className="checkmark" />
          </label>

          <div className="row ">
            <div className="col-6 ">
              <span className="owner-details">
                <img src={EmailIcon} alt="email" />
                {info && info.recipient_email}
              </span>
              <div className="clear-fix" />
            </div>
          </div>
        </ModalRadioCheck>
      );
    });
  };

  const clearAllFilters = (item = modalConfig?.item) => {
    if (uniqueRecipients?.length === 1) {
      setChangeEmailRecipientData({
        ...changeEmailRecipientData,
        current_info: { ...uniqueRecipients[0], id: item?.id },
      });
    } else {
      setChangeEmailRecipientData({
        current_info: {},
        next_all_info: {},
      });
    }
    setFlagsForModal({});
  };

  const checkConditionForEmailChangeConfirmBtn = () => {
    if (
      changeEmailRecipientData?.current_info?.recipient_first_name &&
      changeEmailRecipientData?.current_info?.recipient_last_name &&
      changeEmailRecipientData?.current_info?.recipient_email &&
      !changeEmailRecipientData?.current_info?.recipient_email?.includes(
        '@buyboxexperts.com',
      ) &&
      emailRegex.test(
        changeEmailRecipientData?.current_info?.recipient_email,
      ) &&
      ((flagsForModal?.succeeding_email === 'no' &&
        Object.keys(changeEmailRecipientData?.next_all_info)?.length) ||
        flagsForModal?.succeeding_email === 'yes' ||
        surveyData?.[surveyData?.length - 1]?.id === modalConfig?.item?.id ||
        uniqueRecipients?.length === 1)
    ) {
      return false;
    }
    return true;
  };

  const renderEditRecipientModal = () => {
    return (
      <Modal
        isOpen={modalConfig?.modalFor === 'editRecipient' && modalConfig?.show}
        style={customStylesForTableModal}
        ariaHideApp={false}
        contentLabel="Edit modal"
      >
        <img
          src={CloseIcon}
          alt="close"
          className="float-right cursor cross-icon"
          onClick={() => {
            setModalConfig({
              modalFor: 'editRecipient',
              show: false,
            });
            clearAllFilters();
          }}
          role="presentation"
        />
        <ModalBox>
          <div className="modal-body ">
            <h4 className="on-boarding mb-4">Change Email Recipient</h4>
            <div className="survey-edit-recp-modal">
              {flagsForModal?.addNewContactFlag ? null : (
                <div>{displayContact()}</div>
              )}
              <div className="row">
                <div className="body-content">
                  {flagsForModal?.addNewContactFlag ? null : (
                    <Button
                      className="btn-add-contact"
                      role="presentation"
                      onClick={() => {
                        setFlagsForModal({
                          ...flagsForModal,
                          addNewContactFlag: true,
                        });
                        setChangeEmailRecipientData({
                          ...changeEmailRecipientData,
                          current_info: {},
                        });
                      }}
                    >
                      <img
                        className="mr-2 add-new-icon "
                        src={AddNewIcons}
                        alt="email"
                      />
                      Add New Recipent
                    </Button>
                  )}
                  {flagsForModal?.addNewContactFlag
                    ? displayAddNewContactForm()
                    : null}
                </div>
              </div>
              <div className="row partner-feedback">
                {surveyData?.[surveyData?.length - 1]?.id ===
                modalConfig?.item?.id ? (
                  <GrayMessageBar
                    message="This is the last survey to be sent to the Brand Partner
                      for this contract term."
                  />
                ) : uniqueRecipients?.length === 1 ? null : (
                  <div className="col-12">
                    Will this recipient continue to receive succeeding survey?
                    <div>
                      <ul className="partner-feedback-radiobtn">
                        {emailRecipientOption?.map((item) => {
                          return (
                            <li key={item.label} className="mb-3">
                              <ModalRadioCheck>
                                <label
                                  className=" checkboxes radio-container customer-list"
                                  htmlFor={item.label}
                                >
                                  {item.label}
                                  <input
                                    type="radio"
                                    name="succeeding_survey"
                                    id={item.label}
                                    value={item.value}
                                    onChange={(event) =>
                                      handleEmailRecipientRadioBtn(event)
                                    }
                                    checked={
                                      flagsForModal?.succeeding_email ===
                                      item?.value
                                    }
                                  />
                                  <span className="checkmark checkmark-customer-list" />
                                </label>
                              </ModalRadioCheck>
                            </li>
                          );
                        })}
                      </ul>
                      {flagsForModal?.succeeding_email === 'no' &&
                      uniqueRecipients?.length ? (
                        <div>
                          List of Email Recipient
                          <ul className="partner-feedback-radiobtn">
                            {uniqueRecipients
                              ?.filter(
                                (recipient) =>
                                  recipient?.recipient_email !==
                                  changeEmailRecipientData?.current_info
                                    ?.recipient_email,
                              )
                              ?.map((recipientData) => {
                                return (
                                  <li
                                    key={recipientData.recipient_email}
                                    className="mb-3"
                                  >
                                    <ModalRadioCheck>
                                      <label
                                        className=" checkboxes radio-container customer-list"
                                        htmlFor={`succeding_recipients${recipientData.recipient_email}`}
                                      >
                                        <div>
                                          {recipientData.recipient_first_name}{' '}
                                          {recipientData?.recipient_last_name}
                                        </div>
                                        {recipientData?.recipient_email}
                                        <input
                                          className="justify-content"
                                          type="radio"
                                          name="succeeding_email_recipients"
                                          id={`succeding_recipients${recipientData.recipient_email}`}
                                          value={recipientData.recipient_email}
                                          onChange={() =>
                                            setChangeEmailRecipientData({
                                              ...changeEmailRecipientData,
                                              next_all_info: {
                                                ...recipientData,
                                              },
                                            })
                                          }
                                          checked={
                                            changeEmailRecipientData
                                              ?.next_all_info
                                              ?.recipient_email ===
                                            recipientData?.recipient_email
                                          }
                                        />
                                        <span className="checkmark checkmark-customer-list" />
                                      </label>
                                    </ModalRadioCheck>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className=" mt-4">
              <Button
                className=" btn-primary on-boarding w-100"
                onClick={() => updateRecipient()}
                disabled={checkConditionForEmailChangeConfirmBtn()}
              >
                {isLoading?.loader && isLoading?.type === 'button' ? (
                  <PageLoader color="#fff" type="button" />
                ) : (
                  'Confirm'
                )}
              </Button>
            </div>
          </div>
        </ModalBox>
      </Modal>
    );
  };

  // handle child bp filter events
  const handleChildBPFilterEvents = (event) => {
    if (event?.value !== selectedCustomerId) {
      setSelectedCustomerId(event?.value);
    }
  };

  // get single select dropdown components
  const getSingleSelectComponents = () => {
    return {
      DropdownIndicator,
    };
  };

  // display child bp filter
  const displayChildBPFilter = () => {
    return (
      <SingleSelectDropdown
        filterId="BT-amc-childBrandFilter"
        dropdownOptions={childBPList}
        selectedValue={childBPList?.find(
          (op) => op?.value === selectedCustomerId,
        )}
        onChangeHandler={handleChildBPFilterEvents}
        dropdownComponents={getSingleSelectComponents}
        // isApiCall={isApiCall}
        isSearchable={false}
      />
    );
  };

  const isSendReminderBtnEnabled = (item, itemIndex) => {
    const myDate = new Date(item?.sent_on);
    const newDate = new Date(myDate.setDate(myDate.getDate() + 10));

    // logic to keep send reminder btn enable
    if (
      item?.status === 'sent' &&
      newDate <= new Date() &&
      isAllowToEditOrSkipSurvey
    ) {
      if (
        surveyData?.[itemIndex + 1]?.status === 'sent' ||
        surveyData?.[itemIndex + 1]?.status === 'answered'
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const displayTableContent = (tableData, tableType) => {
    return (
      <tbody
        className={`${
          tableType === 'bpSurveyTable' ? 'scrollable-height' : ''
        }`}
      >
        {tableData?.map((item, index) => (
          <tr
            key={item?.id}
            className={
              item?.status === 'sent'
                ? 'text-medium-font'
                : 'text-medium-font cursor'
            }
            onClick={() => {
              if (item?.status !== 'sent') {
                setShowDetailedFeedback(true);
                setSelectedFeedbackForm(item);
              }
            }}
          >
            <>
              <td>{item.survey_name}</td>
              <td>
                <Status
                  label={item.status}
                  backgroundColor={
                    item.status === 'answered'
                      ? Theme.lightGreen
                      : Theme.lightYellow
                  }
                  pointColor={
                    item.status === 'answered' ? Theme.green : Theme.yellow
                  }
                />
              </td>
              <td>{item.sent_on || 'N/A'}</td>
              <td>{item.completed_on || 'N/A'}</td>
              <td>
                <div>
                  <span>
                    {' '}
                    {item?.recipient_first_name} {item?.recipient_last_name}
                  </span>
                  {item?.status === 'drafted' && isAllowToEditOrSkipSurvey ? (
                    <span
                      className={`float-right `}
                      role="presentation"
                      onClick={(event) => {
                        event.stopPropagation();
                        setModalConfig({
                          modalFor: 'editRecipient',
                          show: true,
                          item,
                        });

                        clearAllFilters(item);
                      }}
                    >
                      <img className="cursor" src={EditOrangeIcon} alt="" />
                    </span>
                  ) : null}
                </div>
                {item?.recipient_email}
              </td>
              {tableType === 'bpSurveyTable' && isAllowToEditOrSkipSurvey ? (
                <td>
                  <Button
                    type="button"
                    disabled={item?.status !== 'drafted'}
                    className={` ${
                      isAllowToEditOrSkipSurvey && item?.status === 'drafted'
                        ? ''
                        : 'btn-disabled'
                    } btn-primary on-boarding mr-3 mb-2`}
                    onClick={(event) => {
                      event.stopPropagation();
                      setModalConfig({
                        modalFor: 'skip',
                        show: true,
                        item,
                      });
                    }}
                    style={{ maxWidth: '130px' }}
                  >
                    Skip Survey
                  </Button>
                  <Button
                    type="button"
                    disabled={!isSendReminderBtnEnabled(item, index)}
                    className={` ${
                      isSendReminderBtnEnabled(item, index)
                        ? ''
                        : 'btn-disabled'
                    } btn-primary on-boarding `}
                    onClick={(event) => {
                      event.stopPropagation();
                      setModalConfig({
                        modalFor: 'sendReminder',
                        show: true,
                        item,
                      });
                    }}
                    style={{ maxWidth: '130px' }}
                  >
                    Send Reminder
                  </Button>
                </td>
              ) : null}
            </>
          </tr>
        ))}
      </tbody>
    );
  };

  const displayTable = (tableData, tableType) => {
    return tableData?.length !== 0 ? (
      <div>
        <DesktopTable className="mt-0">
          <thead>
            <tr>
              <th>
                {tableType === 'bpSurveyTable'
                  ? 'BP Feedback Survey'
                  : 'Onboarding Survey'}
              </th>
              <th>Status</th>
              <th>Survey scheduled on</th>
              <th>Survey answered on</th>
              <th>Recipent Email</th>
              {tableType === 'bpSurveyTable' && isAllowToEditOrSkipSurvey ? (
                <th width={isExtraLargeDesktop ? '' : '130px'}>
                  Action Required
                </th>
              ) : null}
            </tr>
          </thead>

          {displayTableContent(tableData, tableType)}
        </DesktopTable>
      </div>
    ) : null;
  };

  return (
    <>
      <div className="col-12 mb-3">
        {childBPList?.length && customerType === 'parent' ? (
          <WhiteCard className="mb-3">
            <ViewData>
              <div className="row">
                <div className="col-md-4  col-sm-12 ">
                  <div className="view-data-for mt-2 pt-1 ">View data for:</div>{' '}
                </div>
                <div className="col-md-4  mt-2 pt-1 pl-0"> </div>
                <div className="col-md-4 col-12 pl-0">
                  {displayChildBPFilter()}
                </div>
              </div>
            </ViewData>
          </WhiteCard>
        ) : null}
        <WhiteCard className="activity-card">
          <>
            <p className="black-heading-title mt-0 mb-4">Partner Feedback</p>
            {isLoading.loader && isLoading.type === 'page' ? (
              <PageLoader
                component="page"
                color="#FF5933"
                type="page"
                width={40}
                height={40}
              />
            ) : (
              <>
                <div className="mb-4">
                  {displayTable(onboadingsurveyData, 'onboardingSurveyTable')}
                </div>
                <div> {displayTable(surveyData, 'bpSurveyTable')}</div>
                {!onboadingsurveyData?.length && !surveyData?.length ? (
                  <NoRecordFound
                    type="feedback"
                    title="We looked high and low, but…"
                    subTitle="No records found."
                  />
                ) : null}
                <CommonPagination
                  count={surveyCount}
                  pageNumber={pageNumber}
                  handlePageChange={(currentPage) => setPageNumber(currentPage)}
                />
                {renderSkipModal()}
                {renderSendReminderModal()}
                {renderEditRecipientModal()}
              </>
            )}
          </>
        </WhiteCard>
      </div>

      {/* customer feedback detailed view modal */}
      <Modal
        isOpen={showDetailedFeedback}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add team modal"
      >
        <NotesSideBar>
          <div className="row m-0">
            <div className="col-9 uppercase-text py-3 ">
              {selectedFeedbackForm?.survey_name}
            </div>
            <div className="col-3">
              <FontAwesomeIcon
                icon="fa-light fa-times"
                style={{ color: '#000', fontSize: '20px' }}
                onClick={() => setShowDetailedFeedback(false)}
                className="float-right cursor py-3"
              />
              <div className="clear-fix" />
            </div>
            <div className="ml-3 mb-3" style={{ color: 'black' }}>
              {Object.keys(selectedFeedbackForm).includes('feedback') &&
              Object.keys(selectedFeedbackForm).includes('ratings')
                ? 'Survey is collected from BP during the Next account setup process to receive feedback on the process and identify areas for improvements.'
                : 'Survey is collected from BP after four weeks from activating their account.  Survey results are used to determine areas for improvement in the sales and onboarding processes. '}
            </div>
          </div>

          <CustomerFeedbackDetails
            selectedFeedbackForm={selectedFeedbackForm}
            surveyType={
              Object.keys(selectedFeedbackForm).includes('feedback') &&
              Object.keys(selectedFeedbackForm).includes('ratings')
                ? 'accountSetup'
                : 'onboarding'
            }
          />
        </NotesSideBar>
      </Modal>
    </>
  );
}

CustomerFeedbackList.defaultProps = {
  customerType: 'standalone',
  childBPList: [],
  memberData: [],
};

CustomerFeedbackList.propTypes = {
  id: string.isRequired,
  customerType: string,
  childBPList: arrayOf(shape({})),
  memberData: arrayOf(shape({})),
};
