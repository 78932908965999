import React, { useState } from 'react';

import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { useMediaQuery } from 'react-responsive';
import { shape, string, arrayOf } from 'prop-types';

import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetInitialName from './GetInitialName';
import ProfileModal from './Modals/ProfileModal';
import ShareIdeaModal from './Modals/ShareIdeaModal';
import { logout } from '../store/actions/userState';
import { MainHeader } from './Styles/HeaderStyles';

import {
  PATH_CUSTOMER_LIST,
  helpDeskLink,
  managementLink,
  PATH_ACCOUNT_SETUP,
  PATH_CUSTOMER_DETAILS,
} from '../constants';
import {
  NextLogo,
  EditIcons,
  LogOutIcons,
  HelpDeskIcon,
  HandShake,
  FileIcon,
} from '../theme/images';
import Theme from '../theme/Theme';

export default function Header({ userInfo }) {
  const history = useHistory();
  const isBBEInternalUser = userInfo?.isInternalUser ? 'internal' : 'external';
  const customerData = useSelector((state) => state.customerState.data);
  const dispatch = useDispatch();

  const hideHeader = useSelector((state) => state.userState.hideHeader);

  const [showModal, setShowModal] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);
  const [showSuccessMsg, setShowSuccessMsg] = useState({
    show: false,
    message: '',
  });
  const [showArticle, setShowArticle] = useState(false);
  const [showArticleSuccess, setShowArticleSuccess] = useState(false);

  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991.98 });
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const profilePic =
    userInfo?.documents?.[0] && Object.values(userInfo?.documents?.[0])?.[0];

  const toggleDropdown = () => {
    document.getElementById('myDropdown').classList.toggle('show');
  };

  window.addEventListener('click', (e) => {
    if (document.getElementById('clickbox')?.contains(e.target)) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
  });

  $(document).on('click', (e) => {
    if ($(e.target).closest('#kBase').length === 0) {
      $('#mobilemenu-check').prop('checked', false);
    }
  });

  if (isTablet || isMobile) {
    $('#idea').hide();
  } else {
    $('#idea').show();
  }

  // const downloadSampleConteact = () => {
  //   fetch(userInfo?.dummy_contract_url).then((response) => {
  //     response.blob().then((blob) => {
  //       const url = window.URL.createObjectURL(blob);
  //       const a = document.createElement('a');
  //       a.href = url;
  //       a.download = 'employees.json';
  //       a.click();
  //     });
  //     window.location.href = response.url;
  //   });
  // };

  return (
    <div
      className={
        (userInfo?.role?.includes('Customer') &&
          hideHeader === false &&
          !history.location.pathname.includes('/brand-asset/')) ||
        history.location.pathname.includes(PATH_ACCOUNT_SETUP)
          ? 'common-header-sticky'
          : history.location.pathname.includes('/brand-asset/')
          ? 'header-hide'
          : ''
      }
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        pauseOnFocusLoss={false}
      />
      <MainHeader>
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <div
                className="logo cursor"
                onClick={() =>
                  !userInfo?.role.includes('Customer')
                    ? history.push(PATH_CUSTOMER_LIST)
                    : ''
                }
                role="presentation"
              >
                <img src={NextLogo} alt="logo" />
              </div>
              <img
                className="logo-mobile-view cursor"
                src={NextLogo}
                alt=""
                onClick={() =>
                  !userInfo?.role.includes('Customer')
                    ? history.push(PATH_CUSTOMER_LIST)
                    : ''
                }
                role="presentation"
              />
            </div>
            <div className="col-8 text-right">
              <ul className="right-nav">
                <li>
                  <div className="header-user-profile">
                    <div
                      className="dropdown dropbtn"
                      onClick={() => {
                        toggleDropdown();
                        setShowSuccessMsg({ show: false });
                      }}
                      role="presentation"
                    >
                      <div
                        id="clickbox"
                        className="header-profile "
                        style={{
                          paddingTop: profilePic ? '' : '',
                        }}
                      >
                        {profilePic ? (
                          <img
                            src={profilePic}
                            className="profile-pic"
                            alt="user"
                          />
                        ) : (
                          <GetInitialName userInfo={userInfo} />
                        )}
                      </div>
                      <ul
                        className={
                          showDropDown
                            ? 'dropdown-content show'
                            : 'dropdown-content'
                        }
                        id="myDropdown"
                      >
                        <li
                          role="presentation"
                          onClick={() => setShowModal(true)}
                        >
                          <img src={EditIcons} alt="edit" /> Edit profile
                        </li>
                        {!userInfo?.role?.includes('Customer') ? (
                          <>
                            <li
                              role="presentation"
                              onClick={() =>
                                window.open(managementLink, '_blank')
                              }
                            >
                              <img
                                className="mr-2"
                                src={HandShake}
                                alt="edit"
                              />
                              Employee Engagement
                            </li>
                            <li
                              role="presentation"
                              onClick={() =>
                                window.open(helpDeskLink, '_blank')
                              }
                            >
                              <img src={HelpDeskIcon} alt="edit" /> Helpdesk
                            </li>
                            <li role="presentation">
                              <a
                                className="download-pdf-link"
                                href={
                                  userInfo?.amazon_dummy_contract_url
                                    ? userInfo?.amazon_dummy_contract_url
                                    : null
                                }
                                target="_blank"
                                rel="noopener noreferrer "
                                download
                              >
                                <img
                                  src={FileIcon}
                                  alt="download"
                                  className="download-pdf-icon "
                                  role="presentation"
                                />
                                Download Amazon Draft Agreement
                              </a>
                            </li>
                            <li role="presentation">
                              <a
                                className="download-pdf-link"
                                href={
                                  userInfo?.walmart_dummy_contract_url
                                    ? userInfo?.walmart_dummy_contract_url
                                    : null
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                download
                              >
                                <img
                                  src={FileIcon}
                                  alt="download"
                                  className="download-pdf-icon "
                                  role="presentation"
                                />
                                Download Walmart Draft Agreement
                              </a>
                            </li>
                          </>
                        ) : (
                          ''
                        )}
                        <li
                          role="presentation"
                          style={{ cursor: 'pointer' }}
                          onClick={() => dispatch(logout())}
                        >
                          <img src={LogOutIcons} alt="logout" /> Log out
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <ProfileModal
          showModal={showModal}
          setShowModal={setShowModal}
          userInfo={userInfo}
          setShowSuccessMsg={setShowSuccessMsg}
          showSuccessMsg={showSuccessMsg}
        />
        <ShareIdeaModal
          showArticle={showArticle}
          setShowArticle={setShowArticle}
          showArticleSuccess={showArticleSuccess}
          setShowArticleSuccess={setShowArticleSuccess}
        />
      </MainHeader>
      {customerData?.goal_set?.show &&
      customerData?.goal_set?.message &&
      history.location.pathname.includes(`/customer/${customerData?.id}`) &&
      !history.location.search.includes(`goals`) ? (
        <PopOverMsg
          onClick={() =>
            history.push(
              `${PATH_CUSTOMER_DETAILS.replace(
                ':id',
                customerData?.id,
              )}?tab=goals&nav=goals&access=${isBBEInternalUser}`,
            )
          }
        >
          Targets have not been determined for this BP. Please go to “Goals
          View” to update BPs goals{' '}
          <span className="cursor" style={{ color: '#ff5933' }}>
            Click Here{' '}
            <FontAwesomeIcon
              icon="fa-regular fa-chevron-right"
              style={{
                marginLeft: '4px',
                fontSize: '12px',
                cursor: 'pointer',
                color: '#FF5933 ',
              }}
            />
          </span>
        </PopOverMsg>
      ) : null}
    </div>
  );
}

Header.propTypes = {
  userInfo: shape({
    role: string.isRequired,
    first_name: string,
    last_name: string,
    documents: arrayOf(shape({})),
  }).isRequired,
};

const PopOverMsg = styled.div`
  min-height: 40px;
  background: #fbf2f2;
  color: ${Theme.black};
  font-size: 13px;
  padding: 11px;
  text-align: center;
  margin-top: 11px;
  font-family: ${Theme.baseMediumFontFamily};
`;
